<template>
  <div class="view">
    <div
      class="panel flex-column"
    >
      <div class="page-header">
        <h1 class="flex-expand">
          Settings
        </h1>
        <NavFunctions class="nav-functions" />
      </div>
      <TabView
        style="overflow: auto;"
        @tab-change="tabChange"
      >
        <!-- COMPANIES TAB -->
        <TabPanel header="Companies">
          <div class="header-row">
            <div class="flex-input">
              <InputText
                id="company_search"
                v-model="company_search"
                placeholder="Search"
                class="grey-input"
              />
            </div>
            <button
              class="header-button"
              @click="displayPage( companiesData.current_page )"
            >
              Search
            </button>
            <button
              class="header-button"
              style="margin-left: auto;"
              @click="openAccountCreationModal"
            >
              Create Account
            </button>
          </div>
          <div class="pagination-links">
            <p 
              v-show="companiesData.current_page > 1" 
              class="pagination-arrow"
              @click="displayPage( companiesData.current_page - 1 )"
            > 
              &larr; 
            </p>
            <p> 
              Page {{ companiesData.current_page }} of {{ companiesData.page_count }} 
            </p>
            <p 
              v-show="companiesData.current_page < companiesData.page_count"
              class="pagination-arrow"  
              @click="displayPage( companiesData.current_page + 1 )"
            > 
              &rarr; 
            </p>
          </div>
          <DataTable
            v-model:expanded-rows="expandedCompaniesRows"
            :value="companiesData.results"
            :loading="loadingCompaniesData"
            data-key="uuid"
            @row-expand="onCompaniesExpand"
          >
            <template #empty>
              No records found
            </template>
            <template #loading>
              Loading records, please wait...
            </template>
            <Column
              expander
              style="width: 5rem"
            />
            <Column
              field="name"
              header="Name"
            />
            <Column
              field="type"
              header="Type"
            />
            <Column header="Created At">
              <template #body="slotProps">
                {{ $d(new Date(slotProps.data.created_at), 'short') }}
              </template>
            </Column>
            <Column>
              <template #body="slotProps">
                <span class="key-container">
                  <Button
                    icon="pi pi-pencil"
                    outlined
                    rounded
                    severity="info"
                    @click="openEditCompanyModal(slotProps.data)"
                  />
                  <Button
                    icon="pi pi-trash"
                    outlined
                    rounded
                    severity="danger"
                    @click="openConfirmCompanyDeletion(slotProps.data)"
                  />
                </span>
              </template>
            </Column>
            <template #expansion="slotProps">
              <div>
                <div class="flex-row">
                  <h3>Locations</h3>
                  <button
                    class="header-button"
                    @click="openAddPharmacyModal(slotProps.data)"
                  >
                    Create Pharmacy
                  </button>
                </div>
                <!-- PHARMACIES TABLE -->
                <DataTable
                  v-model:expanded-rows="expandedLocationRows"
                  :value="slotProps.data.locations"
                >
                  <!-- LOCATION NAME -->
                  <Column
                    field="name"
                    header="Name"
                  />
                  <!-- LOCATION ADDRESS -->
                  <Column header="Address">
                    <!-- eslint-disable-next-line vue/no-template-shadow -->
                    <template #body="slotProps">
                      {{ slotProps.data.street_address }}, {{ slotProps.data.town }}{{ countryCode == 'AU' ? ' ' + slotProps.data.region : '' }} {{ slotProps.data.postcode }}, {{ slotProps.data.country_name }}
                    </template>
                  </Column>
                  <!-- MODULES -->
                  <Column header="Modules">
                    <!-- eslint-disable-next-line vue/no-template-shadow -->
                    <template #body="locationSlotProps">
                      <span
                        v-if="locationSlotProps.data.modules && locationSlotProps.data.modules.length"
                        style="display: flex; gap: 10px;"
                      >
                        <Tag
                          v-for="(item, index) in locationSlotProps.data.modules"
                          :key="index"
                          :value="item.display_name"
                          :style="tagStyle(item)"
                        />
                      </span>
                    </template>
                  </Column>
                  <!-- UPLOAD STATUS -->
                  <Column header="Upload">
                    <!-- eslint-disable-next-line vue/no-template-shadow -->
                    <template #body="locationSlotProps">
                      <ProgressSpinner
                        v-if="locationSlotProps.data.queue_status === 'LOADING'"
                        class="small-icon"
                        stroke-width="8"
                        animation-duration=".75"
                        fill="var(--surface-ground)"
                        aria-label="Loading"
                      />
                      <Tag
                        v-else
                        style="cursor: pointer;"
                        :value="locationSlotProps.data.queue_status"
                        :severity="getUploadSeverity(locationSlotProps.data.queue_status)"
                        @click="openLocationEtlModal(locationSlotProps.data)"
                      />
                    </template>
                  </Column>
                  <Column>
                    <!-- eslint-disable-next-line vue/no-template-shadow -->
                    <template #body="slotProps">
                      <span class="key-container">
                        <!-- EDIT LOCATION -->
                        <Button
                          icon="pi pi-pencil"
                          outlined
                          rounded
                          severity="info"
                          @click="openEditPharmacyModal(slotProps.data)"
                        />
                        <!-- DELETE LOCATION -->
                        <Button
                          icon="pi pi-trash"
                          outlined
                          rounded
                          severity="danger"
                          @click="openConfirmPharmacyDeletion(slotProps.data)"
                        />
                      </span>
                    </template>
                  </Column>
                  <!-- EXPANDER -->
                  <Column
                    expander
                    style="width: 5rem"
                  />
                  <!-- EXPANDABLE SECTION -->
                  <template
                    #expansion="locationSlotProps"
                  >
                    <LocationDetails
                      :location="locationSlotProps.data"
                    />
                  </template>
                </DataTable>
                <!-- USERS -->
                <div class="flex-row">
                  <h3>Users</h3>
                  <button
                    class="header-button"
                    @click="openAddUserModal(slotProps.data)"
                  >
                    Add User
                  </button>
                </div>
                <DataTable :value="slotProps.data.logins">
                  <Column header="Name">
                    <!-- eslint-disable-next-line vue/no-template-shadow -->
                    <template #body="slotProps">
                      <span v-if="slotProps.data.person && slotProps.data.person.personal_information">
                        {{ slotProps.data.person.personal_information.preferred_name
                          ? slotProps.data.person.personal_information.preferred_name
                          : [slotProps.data.person.personal_information.first_name, slotProps.data.person.personal_information.middle_name, slotProps.data.person.personal_information.last_name].filter(Boolean).join(' ')
                        }}
                      </span>
                    </template>
                  </Column>
                  <Column
                    header="Username"
                    field="name"
                  />
                  <Column
                    header="Email"
                    field="email"
                  />
                </DataTable>
              </div>
            </template>
          </DataTable>
          <button
            class="header-button"
            style="margin-left: auto;"
            @click="openAddCompanyModal"
          >
            Create Company
          </button>
        </TabPanel>

        <!-- USERS TAB -->
        <TabPanel header="Users">
          <div class="header-row">
            <div class="flex-input">
              <InputText
                id="login_search"
                v-model="login_search"
                placeholder="Search"
                class="grey-input"
              />
            </div>
            <button
              class="header-button"
              @click="displayUserPage( usersData.current_page )"
            >
              Search
            </button>
            <button
              class="header-button"
              style="margin-left: auto;"
              @click="openAccountCreationModal"
            >
              Create Account
            </button>
          </div>
          <div class="pagination-links">
            <p 
              v-show="usersData.current_page > 1" 
              class="pagination-arrow"
              @click="displayUserPage( usersData.current_page - 1 )" 
            > 
              &larr; 
            </p>
            <p> 
              Page {{ usersData.current_page }} of {{ usersData.page_count }} 
            </p>
            <p 
              v-show="usersData.current_page < usersData.page_count" 
              class="pagination-arrow" 
              @click="displayUserPage( usersData.current_page + 1 )"
            > 
              &rarr; 
            </p>
          </div>
          <DataTable
            v-model:expanded-rows="expandedUsersRows"
            :value="usersData.results"
            :loading="loadingUsersData"
            data-key="uuid"
          >
            <template #empty>
              No records found
            </template>
            <template #loading>
              Loading records, please wait...
            </template>
            <!-- EXPANDER -->
            <Column
              expander
              style="width: 5rem"
            />
            <Column header="Full Name">
              <template #body="slotProps">
                <span v-if="slotProps.data.person && slotProps.data.person.personal_information">
                  {{ [slotProps.data.person.personal_information.first_name, slotProps.data.person.personal_information.middle_name, slotProps.data.person.personal_information.last_name].filter(Boolean).join(' ') }}
                </span>
              </template>
            </Column>
            <Column
              header="Preferred Name"
              field="person.personal_information.preferred_name"
            />
            <Column
              header="Username"
              field="name"
            />
            <Column
              header="Email"
              field="email"
            />
            <Column
              header="DoB"
              field="person.personal_information.dob"
            />
            <Column
              header="Sex"
              field="person.personal_information.sex"
            />
            <Column header="Date Added">
              <template #body="slotProps">
                {{ $d(new Date(slotProps.data.person.created_at), 'short') }}
              </template>
            </Column>
            <Column>
              <template #body="slotProps">
                <span class="key-container">
                  <Button
                    icon="pi pi-pencil"
                    outlined
                    rounded
                    severity="info"
                    @click="openEditUserModal(slotProps.data)"
                  />
                  <Button
                    icon="pi pi-trash"
                    outlined
                    rounded
                    severity="danger"
                    @click="openConfirmUserDeletion(slotProps.data)"
                  />
                </span>
              </template>
            </Column>
            <!-- EXPANDABLE SECTION -->
            <template #expansion="slotProps">
              <div>
                <!-- USER LOCATIONS TABLE -->
                <UserLocationsTable
                  :user="slotProps.data"
                  :companies="companiesData.results"
                /> 
              </div>
            </template>
          </DataTable>
          <button
            class="header-button"
            style="margin-left: auto;"
            @click="openAddUserModal"
          >
            Create User
          </button>
        </TabPanel>

        <!-- TEMPLATES TAB -->
        <TabPanel header="Templates">
          <SelectButton
            v-model="templatesTabTypeSelection"
            :options="templatesTabTypeOptions"
            :allow-empty="false"
            option-label="key"
            option-value="value"
            style="border:none; padding-top: 1em;"
          />
          <div
            v-if="templatesTabTypeSelection==='recommended'"
          >
            <!-- HEADER ROW -->
            <div class="header-row">
              <!-- PAGINATION -->
              <div class="pagination-links">
                <p 
                  v-show="recommendedTemplateData.current_page > 1" 
                  class="pagination-arrow"
                  @click="displayTemplatePage( recommendedTemplateData.current_page - 1 )" 
                > 
                  &larr; 
                </p>
                <p> 
                  Page {{ recommendedTemplateData.current_page }} of {{ recommendedTemplateData.page_count }} 
                </p>
                <p 
                  v-show="recommendedTemplateData.current_page < recommendedTemplateData.page_count" 
                  class="pagination-arrow" 
                  @click="displayTemplatePage( recommendedTemplateData.current_page + 1 )"
                > 
                  &rarr; 
                </p>
              </div>
              <!-- SELECTED TEMPLATES CLEAR -->
              <div
                v-if="selectedRecommendedTemplates.length > 0"
                class="table-selection"
              >
                <p>
                  {{ selectedRecommendedTemplates.length }} Templates Selected
                </p>
                <Button
                  label="Clear"
                  severity="info"
                  outlined
                  @click="selectedRecommendedTemplates = []"
                />
              </div>
              <Button
                v-if="recommendedTemplateData.templates && recommendedTemplateData.templates.length > 0"
                label="Select All Matching"
                severity="info"
                outlined
                :loading="selectingAllMatchingRecommendedTemplates"
                @click="getAllRecommendedTemplates"
              />
              <!-- MENU ACTIONS -->
              <Button
                type="button"
                severity="info"
                icon="pi pi-ellipsis-v"
                aria-haspopup="true"
                aria-controls="overlay_menu"
                style="margin-left: auto;"
                @click="toggleTemplateMenu"
              />
              <Menu
                id="overlay_menu"
                ref="template_menu"
                :model="recommendedTemplateMenuItems"
                :popup="true"
              />
            </div>
            <DataTable
              v-model:filters="recommendedTemplateFilters"
              v-model:selection="selectedRecommendedTemplates"
              :value="recommendedTemplateData.templates"
              :loading="loadingRecommendedTemplateData"
              compare-selection-by="equals"
              filter-display="row"
              lazy
              data-key="template_recommendation_id"
              :select-all="selectAllRecommendedTemplates"
              @select-all-change="onSelectAllRecommendedTemplatesChange"
              @filter="onFilterTemplate($event)"
            >
              <template #empty>
                No records found
              </template>
              <template #loading>
                Loading records, please wait...
              </template>
              <!-- CHECKBOX COLUMN -->
              <Column
                selection-mode="multiple"
                header-style="width: 3%"
              />
              <!-- TEMPLATE NAME -->
              <Column
                field="name"
                header="Name"
                style="width: 17.5%"
                :show-filter-menu="false"
              >
                <template #filter="{filterModel,filterCallback}">
                  <InputText
                    v-model="filterModel.value"
                    type="text"
                    class="p-column-filter grey-input"
                    placeholder="Search"
                    @keydown.enter="filterCallback()"
                  />
                </template>
              </Column> 
              <!-- TEMPLATE TYPE -->
              <Column
                header="Type"
                field="type"
                style="width: 20%"
                :show-filter-menu="false"
              >
                <template #filter="{filterModel,filterCallback}">
                  <Dropdown
                    v-model="filterModel.value"
                    :options="templateTypes"
                    placeholder="Any"
                    @change="filterCallback"
                  />
                </template>
              </Column>
              <!-- TEMPLATE BODY -->
              <Column
                header="Body"
                field="body"
                style="width: 40%"
                :show-filter-menu="false"
              >
                <template #filter="{filterModel,filterCallback}">
                  <InputText
                    v-model="filterModel.value"
                    type="text"
                    class="p-column-filter grey-input"
                    placeholder="Search"
                    @keydown.enter="filterCallback()"
                  />
                </template>
                <template #body="slotProps">
                  <span v-if="slotProps.data.body">
                    {{ slotProps.data.body }}
                  </span>
                </template>
              </Column>
              <!-- EDIT AND DELETE BUTTONS -->
              <Column
                style="width: 5%"
                :show-filter-menu="false"
              >
                <template #body="slotProps">
                  <span class="key-container">
                    <Button
                      icon="pi pi-pencil"
                      outlined
                      rounded
                      severity="info"
                      @click="openEditRecommendedTemplateModal([slotProps.data])"
                    />
                    <Button
                      icon="pi pi-trash"
                      outlined
                      rounded
                      severity="danger"
                      @click="openConfirmTemplateDeletion([slotProps.data])"
                    />
                  </span>
                </template>
              </Column>
            </DataTable>
          </div>
          <div
            v-else-if="templatesTabTypeSelection==='company'"
          >
            <!-- HEADER ROW -->
            <div class="header-row">
              <!-- PAGINATION -->
              <div class="pagination-links">
                <p 
                  v-show="templateData.current_page > 1" 
                  class="pagination-arrow"
                  @click="displayTemplatePage( templateData.current_page - 1 )" 
                > 
                  &larr; 
                </p>
                <p> 
                  Page {{ templateData.current_page }} of {{ templateData.page_count }} 
                </p>
                <p 
                  v-show="templateData.current_page < templateData.page_count" 
                  class="pagination-arrow" 
                  @click="displayTemplatePage( templateData.current_page + 1 )"
                > 
                  &rarr; 
                </p>
              </div>
              <!-- SELECTED TEMPLATES CLEAR -->
              <div
                v-if="selectedTemplates.length > 0"
                class="table-selection"
              >
                <p>
                  {{ selectedTemplates.length }} Templates Selected
                </p>
                <Button
                  label="Clear"
                  severity="info"
                  outlined
                  @click="selectedTemplates = []"
                />
              </div>
              <Button
                v-if="templateData.results && templateData.results.length > 0"
                label="Select All Matching"
                severity="info"
                outlined
                :loading="selectingAllMatchingTemplates"
                @click="getAllTemplates"
              />
              <!-- MENU ACTIONS -->
              <Button
                type="button"
                severity="info"
                icon="pi pi-ellipsis-v"
                aria-haspopup="true"
                aria-controls="overlay_menu"
                style="margin-left: auto;"
                @click="toggleTemplateMenu"
              />
              <Menu
                id="overlay_menu"
                ref="template_menu"
                :model="templateMenuItems"
                :popup="true"
              />
            </div>
          
            <!-- TEMPLATE TABLE -->
            <DataTable
              v-model:filters="templateFilters"
              v-model:selection="selectedTemplates"
              :value="templateData.results"
              :loading="loadingTemplateData"
              compare-selection-by="equals"
              :select-all="selectAllTemplates"
              lazy
              filter-display="row"
              data-key="uuid"
              @select-all-change="onSelectAllTemplatesChange"
              @filter="onFilterTemplate($event)"
            >
              <template #empty>
                No records found
              </template>
              <template #loading>
                Loading records, please wait...
              </template>
              <!-- CHECKBOX COLUMN -->
              <Column
                selection-mode="multiple"
                header-style="width: 3%"
              />
              <!-- COMPANY NAME -->
              <Column
                header="Company"
                field="company.name"
                style="width: 17.5%;"
                :show-filter-menu="false"
              >
                <template #filter="{filterModel,filterCallback}">
                  <InputText
                    v-model="filterModel.value"
                    type="text"
                    class="p-column-filter grey-input"
                    placeholder="Search"
                    @keydown.enter="filterCallback()"
                  />
                </template>
              </Column>
              <!-- TEMPLATE NAME -->
              <Column
                header="Name"
                field="name"
                style="width: 17.5%"
                :show-filter-menu="false"
              >
                <template #filter="{filterModel,filterCallback}">
                  <InputText
                    v-model="filterModel.value"
                    type="text"
                    class="p-column-filter grey-input"
                    placeholder="Search"
                    @keydown.enter="filterCallback()"
                  />
                </template>
              </Column>
              <!-- TEMPLATE TYPE -->
              <Column
                header="Type"
                field="type"
                style="width: 10%"
                :show-filter-menu="false"
              >
                <template #filter="{filterModel,filterCallback}">
                  <MultiSelect
                    v-model="filterModel.value"
                    :options="templateTypes"
                    placeholder="Any"
                    @change="filterCallback"
                  />
                </template>
              </Column>
              <!-- TEMPLATE ENTERPRISE -->
              <Column
                field="enterprise_template"
                header="Enterprise / Pharmacy"
              >
                <template #filter="{filterModel,filterCallback}">
                  <Dropdown
                    v-model="filterModel.value"
                    :options="['enterprise', 'pharmacy']"
                    placeholder="Any"
                    @change="filterCallback()"
                  />
                </template>
                <template #body="slotProps">
                  <Tag
                    :value="slotProps.data.enterprise_template ? 'Enterprise':'Pharmacy'"
                    :severity="slotProps.data.enterprise_template ? 'warning':'info'"
                    style="margin: 5px;"
                  />
                </template>
              </Column>
              <!-- TEMPLATE BODY -->
              <Column
                header="Body"
                field="body"
                style="width: 35%"
                :show-filter-menu="false"
              >
                <template #filter="{filterModel,filterCallback}">
                  <InputText
                    v-model="filterModel.value"
                    type="text"
                    class="p-column-filter grey-input"
                    placeholder="Search"
                    @keydown.enter="filterCallback()"
                  />
                </template>
              </Column>
              <!-- TEMPLATE ACTIVE STATUS -->
              <Column
                field="active"
                header="Status"
              >
                <template #filter="{filterModel,filterCallback}">
                  <Dropdown
                    v-model="filterModel.value"
                    :options="['active', 'inactive']"
                    placeholder="Any"
                    @change="filterCallback()"
                  />
                </template>
                <template #body="slotProps">
                  <Tag
                    :value="slotProps.data.active ? 'Active':'Inactive'"
                    :severity="slotProps.data.active ? 'success':'danger'"
                    style="margin: 5px;"
                  />
                </template>
              </Column>
              <!-- ACTIVATE / DEACTIVATE TEMPLATES -->
              <Column>
                <template #body="slotProps">
                  <span class="key-container">
                    <Button
                      v-if="slotProps.data.enterprise_template"
                      outlined
                      rounded
                      severity="info"
                      :loading="loadingTemplateAffectedCampaigns"
                      @click="openConfirmActivateDeactivateTemplateModal([slotProps.data])"
                    >
                      {{ slotProps.data.active ? 'Deactivate':'Activate' }}
                    </Button>
                  </span>
                </template>
              </Column>
              <!-- EDIT AND DELETE BUTTONS -->
              <Column
                style="width: 5%"
                :show-filter-menu="false"
              >
                <template #body="slotProps">
                  <span class="key-container">
                    <Button
                      icon="pi pi-pencil"
                      outlined
                      rounded
                      severity="info"
                      @click="openEditTemplateModal([slotProps.data])"
                    />
                    <Button
                      icon="pi pi-trash"
                      outlined
                      rounded
                      severity="danger"
                      @click="openConfirmTemplateDeletion([slotProps.data])"
                    />
                  </span>
                </template>
              </Column>
            </DataTable>
          </div>
          <div
            v-else-if="templatesTabTypeSelection==='global'"
          >
            <div class="header-row">
              <!-- PAGINATION -->
              <div class="pagination-links">
                <p 
                  v-show="globalTemplateData.current_page > 1" 
                  class="pagination-arrow"
                  @click="displayGlobalTemplatePage( globalTemplateData.current_page - 1 )" 
                > 
                  &larr; 
                </p>
                <p> 
                  Page {{ globalTemplateData.current_page }} of {{ globalTemplateData.page_count }} 
                </p>
                <p 
                  v-show="globalTemplateData.current_page < globalTemplateData.page_count" 
                  class="pagination-arrow" 
                  @click="displayGlobalTemplatePage( globalTemplateData.current_page + 1 )"
                > 
                  &rarr; 
                </p>
              </div>            
              <Button
                class="header-button"
                style="margin-left: auto;"
                @click="openAddGlobalTemplateModal"
              >
                Create Global Template
              </Button>
            </div>
            <DataTable
              v-model:filters="globalTemplateFilters"
              :value="globalTemplateData.results"
              :loading="loadingGlobalTemplateData"
              compare-selection-by="equals"
              filter-display="row"
              lazy
              data-key="uuid"
              @filter="onFilterTemplate($event)"
            >
              <template #empty>
                No records found
              </template>
              <template #loading>
                Loading records, please wait...
              </template>
              <!-- TEMPLATE NAME -->
              <Column
                field="name"
                header="Name"
                style="width: 17.5%"
                :show-filter-menu="false"
              >
                <template #filter="{filterModel,filterCallback}">
                  <InputText
                    v-model="filterModel.value"
                    type="text"
                    class="p-column-filter grey-input"
                    placeholder="Search"
                    @keydown.enter="filterCallback()"
                  />
                </template>
              </Column>
              <!-- TEMPLATE TYPE -->
              <Column
                header="Type"
                field="type"
                style="width: 20%"
                :show-filter-menu="false"
              >
                <template #filter="{filterModel,filterCallback}">
                  <MultiSelect
                    v-model="filterModel.value"
                    :options="globalTemplateTypes"
                    placeholder="Any"
                    @change="filterCallback"
                  />
                </template>
              </Column>
              <!-- TEMPLATE BODY -->
              <Column
                header="Body"
                field="body"
                style="width: 40%"
                :show-filter-menu="false"
              >
                <template #filter="{filterModel,filterCallback}">
                  <InputText
                    v-model="filterModel.value"
                    type="text"
                    class="p-column-filter grey-input"
                    placeholder="Search"
                    @keydown.enter="filterCallback()"
                  />
                </template>
                <template #body="slotProps">
                  <span v-if="slotProps.data.body">
                    {{ slotProps.data.body }}
                  </span>
                </template>
              </Column>
              <!-- TEMPLATE ACTIVE STATUS -->
              <Column
                field="active"
                header="Status"
              >
                <template #filter="{filterModel,filterCallback}">
                  <Dropdown
                    v-model="filterModel.value"
                    :options="['active', 'inactive']"
                    placeholder="Any"
                    @change="filterCallback()"
                  />
                </template>
                <template #body="slotProps">
                  <Tag
                    :value="slotProps.data.active ? 'Active':'Inactive'"
                    :severity="slotProps.data.active ? 'success':'danger'"
                    style="margin: 5px;"
                  />
                </template>
              </Column>
              <!-- ACTIVATE / DEACTIVATE TEMPLATES -->
              <Column>
                <template #body="slotProps">
                  <span class="key-container">
                    <Button
                      outlined
                      rounded
                      severity="info"
                      :loading="loadingTemplateAffectedCampaigns"
                      @click="openConfirmActivateDeactivateTemplateModal([slotProps.data])"
                    >
                      {{ slotProps.data.active ? 'Deactivate':'Activate' }}
                    </Button>
                  </span>
                </template>
              </Column>
              <!-- EDIT AND DELETE BUTTONS -->
              <Column
                style="width: 5%"
                :show-filter-menu="false"
              >
                <template #body="slotProps">
                  <span class="key-container">
                    <Button
                      icon="pi pi-pencil"
                      outlined
                      rounded
                      severity="info"
                      @click="openEditGlobalTemplateModal([slotProps.data])"
                    />
                    <Button
                      icon="pi pi-trash"
                      outlined
                      rounded
                      severity="danger"
                      @click="openConfirmTemplateDeletion([slotProps.data])"
                    />
                  </span>
                </template>
              </Column>
            </DataTable>
          </div>
        </TabPanel>
        <!-- CAMPAIGNS TAB-->
        <TabPanel header="Campaigns">
          <!-- RECOMMENDED/COMPANY SELECT BUTTON -->
          <SelectButton
            v-model="campaignsTabTypeSelection"
            :options="campaignsTabTypeOptions"
            :allow-empty="false"
            option-label="key"
            option-value="value"
            style="border:none; padding-top: 1em;"
          />
          <div v-if="campaignsTabTypeSelection==='recommended'">
            <!-- HEADER ROW -->
            <div class="header-row">
              <!-- PAGINATION -->
              <div class="pagination-links">
                <p 
                  v-show="recommendedCampaignsData.current_page > 1" 
                  class="pagination-arrow"
                  @click="displayRecommendedCampaignsPage( recommendedCampaignsData.current_page - 1 )" 
                > 
                  &larr; 
                </p>
                <p> 
                  Page {{ recommendedCampaignsData.current_page }} of {{ recommendedCampaignsData.page_count }} 
                </p>
                <p 
                  v-show="recommendedCampaignsData.current_page < recommendedCampaignsData.page_count" 
                  class="pagination-arrow" 
                  @click="displayRecommendedCampaignsPage( recommendedCampaignsData.current_page + 1 )"
                > 
                  &rarr; 
                </p>
              </div>
              <!-- SELECTED CAMPAIGNS CLEAR -->
              <div
                v-if="selectedRecommendedCampaigns.length > 0"
                class="table-selection"
              >
                <p>
                  {{ selectedRecommendedCampaigns.length }} Campaigns Selected
                </p>
                <Button
                  label="Clear"
                  severity="info"
                  outlined
                  @click="selectedRecommendedCampaigns = []"
                />
              </div>
              <Button
                v-if="recommendedCampaignsData.campaigns && recommendedCampaignsData.campaigns.length > 0"
                label="Select All Matching"
                severity="info"
                outlined
                :loading="selectingAllMatchingRecommendedCampaigns"
                @click="getAllRecommendedCampaigns"
              />
              <!-- MENU ACTIONS -->
              <Button
                type="button"
                severity="info"
                icon="pi pi-ellipsis-v"
                aria-haspopup="true"
                aria-controls="overlay_menu"
                style="margin-left: auto;"
                @click="toggleCampaignMenu"
              />
              <Menu
                id="overlay_menu"
                ref="campaign_menu"
                :model="recommendedCampaignMenuItems"
                :popup="true"
              />
            </div>
            <DataTable
              v-model:filters="recommendedCampaignFilters"
              v-model:selection="selectedRecommendedCampaigns"
              :value="recommendedCampaignsData.campaigns"
              :loading="loadingRecommendedCampaignsData"
              compare-selection-by="equals"
              filter-display="row"
              lazy
              data-key="campaign_recommendation_id"
              :select-all="selectAllRecommendedCampaigns"
              @select-all-change="onSelectAllRecommendedCampaignsChange"
              @filter="onFilterCampaign($event)"
            >
              <template #empty>
                No records found
              </template>
              <template #loading>
                Loading records, please wait...
              </template>
              <!-- CHECKBOX COLUMN -->
              <Column
                selection-mode="multiple"
                header-style="width: 3%"
              />
              <!-- CAMPAIGN NAME -->
              <Column
                field="name"
                header="Campaign Name"
                :show-filter-menu="false"
              >
                <template #filter="{filterModel,filterCallback}">
                  <InputText
                    v-model="filterModel.value"
                    type="text"
                    class="p-column-filter grey-input"
                    placeholder="Search"
                    @keydown.enter="filterCallback()"
                  />
                </template>
              </Column>
              <!-- CAMPAIGN DESCRIPTION -->
              <Column
                field="description"
                header="Description"
                :show-filter-menu="false"
              >
                <template #filter="{filterModel,filterCallback}">
                  <InputText
                    v-model="filterModel.value"
                    type="text"
                    class="p-column-filter grey-input"
                    placeholder="Search"
                    @keydown.enter="filterCallback()"
                  />
                </template>
              </Column>
              <!-- CAMPAIGN GOAL -->
              <Column
                field="goal_type"
                header="Goal Type"
                :show-filter-menu="false"
              >
                <template #filter="{filterModel,filterCallback}">
                  <MultiSelect
                    v-model="filterModel.value"
                    :options="goalOptions"
                    placeholder="Any"
                    @change="filterCallback()"
                  />
                </template>
              </Column>
              <!-- CAMPAIGN GOAL VALUE -->
              <Column
                header="Goal"
                :show-filter-menu="false"
              >
                <template #body="slotProps">
                  <span v-if="slotProps.data.goal">
                    {{ slotProps.data.measure_by === "target" ? "to" : "by" }} {{ slotProps.data.measure_by !== "percentage" && slotProps.data.goal_type === "revenue" ? $t('currencySymbol') : "" }}{{ $n(slotProps.data.goal, 'decimal') }}{{ slotProps.data.measure_by === "percentage" || (['adherence', 'loyalty'].includes(slotProps.data.goal_type)) ? "%" : "" }}
                  </span>
                </template>
              </Column>
              <!-- CAMPAIGN ATTACHED TEMPLATES -->
              <Column
                header="Templates"
              >
                <template #body="slotProps">
                  <span v-if="slotProps.data.template_recommendations && slotProps.data.template_recommendations.length">
                    <span
                      v-for="template in slotProps.data.template_recommendations"
                      :key="template.template_id"
                    >
                      <Tag
                        :value="template.template_name"
                        style="background-color:#7B7E7E;margin: 5px;"
                      />
                    </span>
                  </span>
                </template>
              </Column>

              <!-- EDIT AND DELETE BUTTONS -->
              <Column
                style="width: 5%"
                :show-filter-menu="false"
              >
                <template #body="slotProps">
                  <span class="key-container">
                    <Button
                      icon="pi pi-pencil"
                      outlined
                      rounded
                      severity="info"
                      @click="openEditRecommendedCampaignModal([slotProps.data])"
                    />
                    <Button
                      icon="pi pi-trash"
                      outlined
                      rounded
                      severity="danger"
                      @click="openConfirmCampaignDeletion([slotProps.data])"
                    />
                  </span>
                </template>
              </Column>
            </DataTable>
          </div>
          <div v-else-if="campaignsTabTypeSelection==='company'">
            <!-- HEADER ROW -->
            <div class="header-row">
              <!-- PAGINATION -->
              <div class="pagination-links">
                <p 
                  v-show="campaignsData.current_page > 1" 
                  class="pagination-arrow"
                  @click="displayCampaignsPage( campaignsData.current_page - 1 )" 
                > 
                  &larr; 
                </p>
                <p> 
                  Page {{ campaignsData.current_page }} of {{ campaignsData.page_count }} 
                </p>
                <p 
                  v-show="campaignsData.current_page < campaignsData.page_count" 
                  class="pagination-arrow" 
                  @click="displayCampaignsPage( campaignsData.current_page + 1 )"
                > 
                  &rarr; 
                </p>
              </div>
              <!-- SELECTED CAMPAIGNS CLEAR -->
              <div
                v-if="selectedCampaigns.length > 0"
                class="table-selection"
              >
                <p>
                  {{ selectedCampaigns.length }} Campaigns Selected
                </p>
                <Button
                  label="Clear"
                  severity="info"
                  outlined
                  @click="selectedCampaigns = []"
                />
              </div>
              <Button
                v-if="campaignsData.campaigns && campaignsData.campaigns.length > 0"
                label="Select All Matching"
                severity="info"
                outlined
                :loading="selectingAllMatchingCampaigns"
                @click="getAllCampaigns"
              />
              <!-- MENU ACTIONS -->
              <Button
                type="button"
                severity="info"
                icon="pi pi-ellipsis-v"
                aria-haspopup="true"
                aria-controls="overlay_menu"
                style="margin-left: auto;"
                @click="toggleCampaignMenu"
              />
              <Menu
                id="overlay_menu"
                ref="campaign_menu"
                :model="campaignMenuItems"
                :popup="true"
              />
            </div>
            <DataTable
              v-model:filters="campaignFilters"
              v-model:selection="selectedCampaigns"
              :value="campaignsData.campaigns"
              :loading="loadingCampaignsData"
              compare-selection-by="equals"
              filter-display="row"
              lazy
              data-key="campaign_id"
              :select-all="selectAllCampaigns"
              @select-all-change="onSelectAllCampaignsChange"
              @filter="onFilterCampaign($event)"
            >
              <template #empty>
                No records found
              </template>
              <template #loading>
                Loading records, please wait...
              </template>
              <!-- CHECKBOX COLUMN -->
              <Column
                selection-mode="multiple"
                header-style="width: 3%"
              />
              <!-- CAMPAIGN COMPANY NAME -->
              <Column
                field="company.name"
                header="Company"
                :show-filter-menu="false"
              >
                <template #filter="{filterModel,filterCallback}">
                  <InputText
                    v-model="filterModel.value"
                    type="text"
                    class="p-column-filter grey-input"
                    placeholder="Search"
                    @keydown.enter="filterCallback()"
                  />
                </template>
              </Column>
              <!-- CAMPAIGN NAME -->
              <Column
                field="name"
                header="Campaign Name"
                :show-filter-menu="false"
              >
                <template #filter="{filterModel,filterCallback}">
                  <InputText
                    v-model="filterModel.value"
                    type="text"
                    class="p-column-filter grey-input"
                    placeholder="Search"
                    @keydown.enter="filterCallback()"
                  />
                </template>
              </Column>
              <!-- CAMPAIGN GOAL -->
              <Column
                field="goal_type"
                header="Goal Type"
                :show-filter-menu="false"
              >
                <template #filter="{filterModel,filterCallback}">
                  <MultiSelect
                    v-model="filterModel.value"
                    :options="goalOptions"
                    placeholder="Any"
                    @change="filterCallback()"
                  />
                </template>
              </Column>
              <!-- CAMPAIGN GOAL VALUE -->
              <Column
                header="Goal"
                :show-filter-menu="false"
              >
                <template #body="slotProps">
                  <span v-if="slotProps.data.goal">
                    {{ slotProps.data.measure_by === "target" ? "to" : "by" }}
                    <span v-if="slotProps.data.measure_by !== 'percentage' && slotProps.data.goal_type === 'revenue'">
                      {{ $n(slotProps.data.goal, 'currency') }}
                    </span>
                    <span v-else-if="slotProps.data.measure_by === 'percentage' || ['adherence', 'loyalty'].includes(slotProps.data.goal_type)">
                      {{ $n(slotProps.data.goal, 'percent') }}
                    </span>
                    <span v-else>
                      {{ $n(slotProps.data.goal, 'integer') }}
                    </span>
                  </span>
                </template>
              </Column>
              <!-- CAMPAIGN ATTACHED TEMPLATES -->
              <Column
                header="Templates"
                style="width: 17.5%"
              >
                <template #body="slotProps">
                  <span v-if="slotProps.data.templates && slotProps.data.templates.length">
                    <span
                      v-for="template in slotProps.data.templates"
                      :key="template.template_id"
                    >
                      <Tag
                        :value="template.template_name"
                        style="background-color:#7B7E7E;margin: 5px;"
                      />
                    </span>
                  </span>
                </template>
              </Column>
              <Column
                field="active"
                header="Status"
              >
                <template #filter="{filterModel,filterCallback}">
                  <Dropdown
                    v-model="filterModel.value"
                    :options="['active', 'inactive']"
                    placeholder="Any"
                    @change="filterCallback()"
                  />
                </template>
                <template #body="slotProps">
                  <Tag
                    :value="slotProps.data.active ? 'Active':'Inactive'"
                    :severity="slotProps.data.active ? 'success':'danger'"
                    style="margin: 5px;"
                  />
                </template>
              </Column>
              <Column>
                <template #body="slotProps">
                  <span class="key-container">
                    <Button
                      outlined
                      rounded
                      severity="info"
                      :loading="loadingCampaignAffectedTemplates"
                      @click="openConfirmActivateDeactivateCampaignModal([slotProps.data])"
                    >
                      {{ slotProps.data.active ? 'Deactivate':'Activate' }}
                    </Button>
                  </span>
                </template>
              </Column>
              <!-- EDIT AND DELETE BUTTONS -->
              <Column
                style="width: 5%"
                :show-filter-menu="false"
              >
                <template #body="slotProps">
                  <span class="key-container">
                    <Button
                      icon="pi pi-pencil"
                      outlined
                      rounded
                      severity="info"
                      @click="openEditCampaignModal([slotProps.data])"
                    />
                    <Button
                      icon="pi pi-trash"
                      outlined
                      rounded
                      severity="danger"
                      @click="openConfirmCampaignDeletion([slotProps.data])"
                    />
                  </span>
                </template>
              </Column>
            </DataTable>
          </div>
        </TabPanel> 
        <!-- SUPPORT DASHBOARD TAB -->
        <TabPanel header="Uploads">
          <div class="grid-container">
            <div class="left-col">
              <!-- UPLOAD STATS SUMMARY -->
              <div
                class="upload-stats flex-expand"
                :style="loadingFaultyEtlPharmacyData ? `gap: 10px;` : ``"
              >
                <!-- STRONG PRO PHARMACIES SUMMARY -->
                <div
                  v-if="!loadingFaultyEtlPharmacyData"
                  class="upload_card"
                >
                  <div class="stats-top-row">
                    <p class="stats-heading">
                      Strong Pro Pharmacies
                    </p>
                  </div>
              
                  <h3
                    v-if="etlStatsData && etlStatsData['TOTAL']"
                    class="stats-value"
                  >
                    {{ Intl.NumberFormat().format(etlStatsData['TOTAL']) }}
                  </h3>
                </div>
                <Skeleton
                  v-else
                  class="stats-skeleton"
                />
                <!-- NO/ERROR UPLOAD PHARMACIES SUMMARY -->
                <div
                  v-if="!loadingFaultyEtlPharmacyData"
                  class="upload_card"
                >
                  <div class="stats-top-row">
                    <p class="stats-heading">
                      Pharmacies with no/error uploads
                    </p>
                  </div>
              
                  <h3
                    v-if="etlStatsData && etlStatsData.noUploadPharmacies"
                    class="stats-value"
                  >
                    {{ Intl.NumberFormat().format(etlStatsData.noUploadPharmacies) }}
                  </h3>
                </div>
                <Skeleton
                  v-else
                  class="stats-skeleton"
                />
                <!-- NEVER UPLOADED PHARMACIES SUMMARY -->
                <div
                  v-if="!loadingFaultyEtlPharmacyData"
                  class="upload_card"
                >
                  <div class="stats-top-row">
                    <p class="stats-heading">
                      Pharmacies Never Uploaded 
                    </p>
                  </div>
              
                  <h3
                    v-if="etlStatsData && etlStatsData['NEVER UPLOADED']"
                    class="stats-value"
                  >
                    {{ Intl.NumberFormat().format(etlStatsData['NEVER UPLOADED']) }}
                  </h3>
                </div>
                <Skeleton
                  v-else
                  class="stats-skeleton"
                />
                <!-- SUCCESSFULLY UPLOADED PHARMACIES SUMMARY -->
                <div
                  v-if="!loadingFaultyEtlPharmacyData"
                  class="upload_card"
                >
                  <div class="stats-top-row">
                    <p class="stats-heading">
                      Pharmacies Successfully Uploaded 
                    </p>
                  </div>
              
                  <h3
                    v-if="etlStatsData && etlStatsData['COMPLETED']"
                    class="stats-value"
                  >
                    {{ Intl.NumberFormat().format(etlStatsData['COMPLETED']) }}
                  </h3>
                  <h3
                    v-else
                    class="stats-value"
                  >
                    {{ Intl.NumberFormat().format(0) }}
                  </h3>
                </div>
                <Skeleton
                  v-else
                  class="stats-skeleton"
                />
              </div>
              <!-- FILTERS FOR TIME PERIOD TO CHECK FOR ERROR UPLOADS -->
              <div class="upload-header-row">
                <h3 style="margin: auto 0 auto 0;">
                  Pharmacies with no/error uploads in last {{ selectedInputNoUploads }} {{ selectedPeriodTypeNoUploads }}
                </h3>
              <!-- <div class="flex-input">
                  <InputText
                    v-model="selectedInputNoUploadsLocal"
                    class="grey-input"
                    type="number"
                    style="width: 100px; margin-left: 1em;"
                    placeholder="Enter a number"
                  />
                </div>
                <div class="flex-input">
                  <Dropdown
                    v-model="selectedPeriodTypeNoUploadsLocal"
                    :options="periods"
                    placeholder="Select a Period"
                    default="Hours"
                    class="flex-item"
                  />
                </div>
                <button
                  class="header-button"
                  @click="updateFaultyUploadFilter(selectedPeriodTypeNoUploadsLocal, selectedInputNoUploadsLocal)"
                >
                  Update
                </button> -->
              </div>
              <DataTable
                v-model:expanded-rows="expandedProPharmaciesRow"
                v-model:filters="faultyUploadFilters"
                :value="faultyEtlPharmacyData"
                :loading="loadingFaultyEtlPharmacyData"
                compare-selection-by="equals"
                data-key="pharmacy.uuid"
                filter-display="row"
                sort-field="sortStatusField"
                :sort-order="-1"
                paginator
                :rows="10"
                :rows-per-page-options="[5, 10, 20, 50]"
              >
                <template #empty>
                  No records found
                </template>
                <template #loading>
                  Loading records, please wait...
                </template>
                <!-- PHARMACY NAME -->
                <Column
                  expander
                  style="width: 5rem"
                />
                <Column
                  header="Pharmacy"
                  field="pharmacy.name"
                  style="width: 25%;"
                >
                  <template #filter="{ filterModel, filterCallback }">
                    <InputText
                      v-model="filterModel.value"
                      type="text"
                      class="p-column-filter grey-input"
                      placeholder="Search by name"
                      @input="filterCallback()"
                    />
                  </template>
                </Column>
                
                <!-- PHARMACY LAST UPDATED TIME -->
                <Column
                  header="Last Updated Time"
                  style="width: 25%"
                  :show-filter-menu="false"
                  :sortable="true"
                  sort-field="uploadTimestamp"
                >
                  <template #body="slotProps">
                    <span v-if="slotProps.data.status !== 'NOT RECEIVED' && slotProps.data.upload_time !== null">
                      {{ slotProps.data.upload_time.toLocaleString('en-AU') }}
                    </span>
                  </template>
                </Column>
                <!-- UPLOAD STATUS -->
                <Column
                  header="Upload Status"
                  filter-field="status"
                  style="width: 20%"
                  :sortable="true"
                  sort-field="sortStatusField"
                >
                  <!-- eslint-disable-next-line vue/no-template-shadow -->
                  <template #filter="{ filterModel, filterCallback }">
                    <MultiSelect
                      v-model="filterModel.value"
                      placeholder="All"
                      :options="uploadStatuses"
                      
                      class="p-column-filter grey-input"
                      @change="filterCallback()"
                    />
                  </template>
                  <template #body="slotProps">
                    <ProgressSpinner
                      v-if="slotProps.data.status === 'LOADING'"
                      class="small-icon"
                      stroke-width="8"
                      animation-duration=".75"
                      fill="var(--surface-ground)"
                      aria-label="Loading"
                    />
                    <Tag
                      v-else
                      style="cursor: pointer;"
                      :value="slotProps.data.status"
                      :style="etlStatusTagStyle(slotProps.data.status)"
                      :severity="getUploadSeverity(slotProps.data.status)"
                      @click="openLocationEtlModal(slotProps.data.pharmacy, true)"
                    />
                  </template>
                </Column>
                <!-- PHARMACY LAST SUCCESSFUL UPLOAD TIME -->
                <Column
                  header="Last Successful Upload Time"
                  style="width: 25%"
                  :show-filter-menu="false"
                >
                  <template #body="slotProps">
                    <span v-if="slotProps.data.last_successful_upload_on">
                      {{ slotProps.data.last_successful_upload_on.toLocaleString('en-AU') }}
                    </span>
                  </template>
                </Column>
                <template #expansion="slotProps">
                  <!-- PHARMACY MODULES -->
                  <LocationDetails
                    :location="slotProps.data.pharmacy"
                  />
                </template>
              </DataTable>
            </div>
            <div class="right-col">
              <!-- PHARMACY PIE CHART BASED ON NUMBER OF PHARMACIES WITH RESPECTIVE STATUSES -->
              <Chart
                v-if="renderUploadChart"
                type="doughnut"
                :data="uploadsPieChartData"
                :options="uploadsPieChartOptions"
                class="w-full md:w-30rem"
              />
            </div>
          </div>
        </TabPanel>
        <!-- CLINICAL TRIALS TAB -->
        <TabPanel header="Clinical Trials">
          <div class="header-row">
            <div class="flex-input">
              <InputText
                id="clinical_trial_search"
                v-model="clinical_trial_search"
                placeholder="Search"
                class="grey-input"
              />
            </div>
            <button
              class="header-button"
              @click="displayClinicalTrialsPage( clinicalTrialsData.current_page )"
            >
              Search
            </button>
            <button
              class="header-button"
              style="margin-left: auto;"
              @click="openAddRecruiterProviderModal"
            >
              Create Recruiter / Provider
            </button>
            <button
              class="header-button"
              @click="openAddClinicalTrialModal"
            >
              Create Trial
            </button>
          </div>
          <div class="pagination-links">
            <p 
              v-show="clinicalTrialsData.current_page > 1" 
              class="pagination-arrow"
              @click="displayClinicalTrialsPage( clinicalTrialsData.current_page - 1 )"
            > 
              &larr; 
            </p>
            <p> 
              Page {{ clinicalTrialsData.current_page }} of {{ clinicalTrialsData.page_count }} 
            </p>
            <p 
              v-show="clinicalTrialsData.current_page < clinicalTrialsData.page_count"
              class="pagination-arrow"  
              @click="displayClinicalTrialsPage( clinicalTrialsData.current_page + 1 )"
            > 
              &rarr; 
            </p>
          </div>
          <DataTable
            v-model:expanded-rows="expandedClinicalTrialsRows"
            :value="clinicalTrialsData.clinical_trials"
            :loading="loadingClinicalTrialsData"
            data-key="trial_id"
            @row-expand="onClinicalTrialsExpand"
          >
            <template #empty>
              No records found
            </template>
            <template #loading>
              Loading records, please wait...
            </template>
            <Column
              expander
              style="width: 5rem"
            />
            <Column
              field="name"
              header="Name"
            />
            <Column
              field="note"
              header="Note"
            />
            <Column header="Start Date">
              <template #body="slotProps">
                {{ $d(new Date(slotProps.data.start_date), 'short') }}
              </template>
            </Column>
            <Column header="End Date">
              <template #body="slotProps">
                <span v-if="slotProps.data.end_date">
                  {{ $d(new Date(slotProps.data.end_date), 'short') }}
                </span>
              </template>
            </Column>
            <Column
              field="active"
              header="Status"
            >
              <template #filter="{filterModel,filterCallback}">
                <Dropdown
                  v-model="filterModel.value"
                  :options="['active', 'inactive']"
                  placeholder="Any"
                  @change="filterCallback()"
                />
              </template>
              <template #body="slotProps">
                <Tag
                  :value="slotProps.data.active ? 'Active':'Inactive'"
                  :severity="slotProps.data.active ? 'success':'danger'"
                  style="margin: 5px;"
                />
              </template>
            </Column>
            <Column>
              <template #body="slotProps">
                <span class="key-container">
                  <Button
                    outlined
                    rounded
                    severity="info"
                    :loading="loadingClinicalTrialsData"
                    @click="openConfirmActivateDeactivateClinicalTrialModal(slotProps.data)"
                  >
                    {{ slotProps.data.active ? 'Deactivate':'Activate' }}
                  </Button>
                  <Button
                    icon="pi pi-pencil"
                    outlined
                    rounded
                    severity="info"
                    @click="openEditClinicalTrialModal(slotProps.data)"
                  />
                  <!-- <Button
                    icon="pi pi-trash"
                    outlined
                    rounded
                    severity="danger"
                    @click="openConfirmCompanyDeletion(slotProps.data)"
                  /> -->
                </span>
              </template>
            </Column>
            <template #expansion="slotProps">
              <div>
                <div class="flex-row item-data">
                  <span class="detail-item-header">Recruiter</span>
                  <span class="detail-item-value">{{ slotProps.data.recruiter.name }}</span>
                  <Button
                    icon="pi pi-pencil"
                    outlined
                    rounded
                    severity="info"
                    @click="openEditRecruiterProviderModal(slotProps.data.recruiter)"
                  />
                </div>
                <div class="flex-row item-data">
                  <span class="detail-item-header">Provider</span>
                  <span class="detail-item-value">{{ slotProps.data.provider.name }}</span>
                  <Button
                    icon="pi pi-pencil"
                    outlined
                    rounded
                    severity="info"
                    @click="openEditRecruiterProviderModal(slotProps.data.provider)"
                  />
                </div>
                <div class="flex-row">
                  <h3>Eligibility Criteria</h3>
                </div>
                <div class="flex-row">
                  <ul>
                    <li 
                      v-for="criteria in eligibilityCriteriaList(slotProps.data.eligibility_criteria)"
                      :key="criteria"
                    >
                      {{ criteria }}
                    </li>
                  </ul>
                </div>
                <div class="flex-row">
                  <h3>Locations</h3>
                </div>
                <!-- PHARMACIES TABLE -->
                <DataTable
                  :value="slotProps.data.locations"
                  :loading="slotProps.data.loadingExandedRow"
                  paginator
                  lazy
                  :rows="slotProps.data.pageSize"
                  :total-records="slotProps.data.numLocations"
                  @page="onLocationPageChange(slotProps.data, $event)"
                >
                  <template #empty>
                    No records found
                  </template>
                  <template #loading>
                    Loading records, please wait...
                  </template>
                  <!-- LOCATION NAME -->
                  <Column
                    field="location_name"
                    header="Name"
                  />
                  <!-- NUMBER OF ELIGIBLE PATIENTS -->
                  <Column 
                    field="total_eligible"
                    header="Eligible Patients"
                  />
                  <!-- NUMBER OF CONTACTED PATIENTS -->
                  <Column 
                    field="total_contacted"
                    header="Contacted Patients"
                  />
                  <!-- NUMBER OF REFERRED PATIENTS -->
                  <Column 
                    field="total_referred"
                    header="Referred Patients"
                  />
                  <!-- NUMBER OF OPT OUT PATIENTS -->
                  <Column 
                    field="total_optout"
                    header="Opt Out Patients"
                  />
                </DataTable>
              </div>
            </template>
          </DataTable>
        </TabPanel>
      </TabView>
    </div>
  </div>
  <ConfirmDialog
    group="settings_confirmation"
    :pt="{message: { style: 'white-space: pre-line !important;' }}"
  />
  <AccountCreationModal
    v-if="displayAccountCreationModal"
    :modules="modules"
    @close-modal="closeModal"
  />
  <CreatePharmacyModal
    v-if="displayAddPharmacyModal"
    :company="currentCompany"
    :modules="modules"
    @close-modal="closeModal"
  />
  <CreateCompanyModal
    v-if="displayAddCompanyModal"
    @close-modal="closeModal"
  />
  <CreateUserModal
    v-if="displayAddUserModal"
    :company-id="currentCompany.uuid"
    @close-modal="closeModal"
  />
  <EditUserModal
    v-if="displayEditUserModal"
    :user="currentUser"
    @close-modal="closeModal"
  />
  <EditCompanyModal
    v-if="displayEditCompanyModal"
    :company="currentCompany"
    @close-modal="closeModal"
  />
  <EditPharmacyModal
    v-if="displayEditPharmacyModal"
    :pharmacy="currentPharmacy"
    :modules="modules"
    @close-modal="closeModal"
    @update-pharmacy="updatePharmacy"
  />
  <LocationEtlModal
    v-if="displayLocationEtlModal"
    :pharmacy="currentPharmacy"
    :initial-refresh="locationEtlQueueInitialRefresh"
    @close-modal="closeModal"
    @update-queue="updateQueue"
  />
  <!-- <AdminEditTemplateModal
    v-if="displayEditTemplateModal"
    :templates="currentTemplates"
    :confirmation-details="confirmationDetails"
    @close-modal="closeModal"
    @template-edited="templateEdited"
  /> -->
  <AdminCreateEditTemplateModal
    v-if="displayAddEditTemplateModal"
    :templates="templateAddEditMode==='create' ? [] : currentTemplates"
    :confirmation-details="confirmationDetails"
    @close-modal="closeModal"
    @template-created="templateCreated"
    @template-edited="templateEdited"
  />
  <!-- <AdminCreateTemplateModal
    v-if="displayAddTemplateModal"
    @close-modal="closeModal"
    @template-created="templateCreated"
  /> -->
  <AdminCreateEditRecommendedCampaignModal
    v-if="displayAddEditRecommendedCampaignModal"
    :campaigns="recommendedCampaignAddEditMode==='create' ? [] : currentRecommendedCampaigns"
    :confirmation-details="confirmationDetails"
    @campaign-created="recommendedCampaignCreated"
    @campaign-edited="recommendedCampaignEdited"
    @close-modal="closeModal"
  />
  <AdminCreateEditRecommendedTemplateModal
    v-if="displayAddEditTemplateRecommendedModal"
    :templates="recommendedTemplateAddEditMode==='create' ? [] : currentRecommendedTemplates"
    :confirmation-details="confirmationDetails"
    @template-created="recommendedTemplateCreated"
    @template-edited="recommendedTemplateEdited"
    @close-modal="closeModal"
  />
  <AdminCreateEditCampaignModal
    v-if="displayAddEditCampaignModal"
    :campaigns="campaignAddEditMode==='create' ? [] : currentCampaigns"
    :confirmation-details="confirmationDetails"
    @campaign-created="campaignCreated"
    @campaign-edited="campaignEdited"
    @close-modal="closeModal"
  />
  <AdminCreatedEditGlobalTemplateModal
    v-if="displayAddEditGlobalTemplateModal"
    :templates="globalTemplateAddEditMode==='create' ? [] : currentGlobalTemplates"
    :confirmation-details="confirmationDetails"
    @global-template-created="globalTemplateCreated"
    @global-template-edited="globalTemplateEdited"
    @close-modal="closeModal"
  />
  <AdminCreateEditClinicalTrialModal
    v-if="displayAddEditClinicalTrialModal"
    :clinical-trial="clinicalTrialAddEditMode==='create' ? {} : selectedClinicalTrial"
    @trial-created="clinicalTrialCreated"
    @trial-edited="clinicalTrialEdited"
    @close-modal="closeModal"
  />
  <AdminCreateEditRecruiterProviderModal
    v-if="displayAddEditRecruiterProviderModal"
    :recruiter-provider="recruiterProviderAddEditMode==='create' ? {} : selectedRecruiterProvider"
    @recruiter-provider-created="recruiterProviderCreated"
    @recruiter-provider-edited="recruiterProviderEdited"
    @close-modal="closeModal"
  />
  <ActivateDeactivateClinicalTrialConfirmationModal
    v-if="displayActivateDeactivateClinicalTrialConfirmationModal"
    :title="'Confirm your changes'"
    :message="getActivateDeactivateClinicalTrialConfirmationMessage()"
    :active="!selectedClinicalTrial.active"
    @confirm="activateDeactivateClinicalTrial"
    @close-modal="closeModal"
  />
</template>
<script>
import NavFunctions from '@/components/navigation/nav-menu-dropdown.vue'
import AccountCreationModal from '@/components/modals/account-creation-modal.vue'
import CreatePharmacyModal from '@/components/modals/create-pharmacy-modal.vue'
import CreateCompanyModal from '@/components/modals/create-company-modal.vue'
import CreateUserModal from '@/components/modals/create-user-modal.vue'
import UserLocationsTable from '@/components/tables/user-locations-table.vue'
import LocationDetails from '@/components/tables/location-details.vue'
import EditUserModal from '@/components/modals/edit-user-modal.vue'
import EditCompanyModal from '@/components/modals/edit-company-modal.vue'
import EditPharmacyModal from '@/components/modals/edit-pharmacy-modal.vue'
import LocationEtlModal from '@/components/modals/location-etl-modal.vue'
// import AdminEditTemplateModal from '@/components/modals/admin-edit-template-modal.vue'
// import AdminCreateTemplateModal from '@/components/modals/admin-create-template-modal.vue'
import AdminCreateEditTemplateModal from '@/components/modals/admin-create-edit-template-modal.vue'
import AdminCreateEditRecommendedCampaignModal from '@/components/modals/admin-create-edit-recommended-campaign-modal.vue'
import AdminCreateEditRecommendedTemplateModal from '@/components/modals/admin-create-edit-recommended-template-modal.vue'
import AdminCreatedEditGlobalTemplateModal from '@/components/modals/admin-create-edit-global-template-modal.vue'
import AdminCreateEditCampaignModal from '@/components/modals/admin-create-edit-campaign-modal.vue'
import AdminCreateEditClinicalTrialModal from '@/components/modals/admin-create-edit-clinical-trial-modal.vue'
import AdminCreateEditRecruiterProviderModal from '@/components/modals/admin-create-edit-recruiter-provider-modal.vue'
import ActivateDeactivateClinicalTrialConfirmationModal from '@/components/modals/activate-deactivate-clinical-trial-confirmation-modal.vue'
import { useConfirm } from 'primevue/useconfirm';
import Chart from 'primevue/chart';
import { FilterMatchMode } from 'primevue/api';
import axios from 'axios';

export default {
  components: {
    NavFunctions,
    CreatePharmacyModal,
    CreateCompanyModal,
    CreateUserModal,
    UserLocationsTable,
    LocationDetails,
    EditUserModal,
    EditCompanyModal,
    EditPharmacyModal,
    LocationEtlModal,
    AccountCreationModal,
    // AdminEditTemplateModal,
    // AdminCreateTemplateModal,
    AdminCreateEditTemplateModal,
    AdminCreateEditRecommendedCampaignModal,
    AdminCreateEditRecommendedTemplateModal,
    AdminCreatedEditGlobalTemplateModal,
    AdminCreateEditCampaignModal,
    AdminCreateEditClinicalTrialModal,
    AdminCreateEditRecruiterProviderModal,
    ActivateDeactivateClinicalTrialConfirmationModal,
    Chart
  },
  data() {
    return {
      companiesData: [],
      companiesDataError: '',
      loadingCompaniesData: false,
      expandedCompaniesRows: [],
      expandedLocationRows: [],
      currentCompany: {},
      currentUser: {},
      currentPharmacy: {},
      currentTemplates: [],
      confirmationDetails: {},
      displayAccountCreationModal: false,
      displayAddPharmacyModal: false,
      displayAddCompanyModal: false,
      displayAddUserModal: false,
      displayEditUserModal: false,
      displayEditCompanyModal: false,
      displayEditPharmacyModal: false,
      displayLocationEtlModal: false,
      displayEditTemplateModal: false,
      displayAddEditTemplateModal: false,
      selectingAllMatchingTemplates: false,
      usersData: [],
      usersDataError: '',
      loadingUsersData: false,
      expandedUsersRows: [],
      templateData: [],
      templateDataError: '',
      paginateGetTemplates: true,
      loadingTemplateData: false,
      selectedTemplates: [],
      templateAddEditMode: 'create',
      templateFilters: {
        'company.name': {value: null},
        'name': {value: null},
        'type': {value: null},
        'body': {value: null},
        'active': {value: null},
        'enterprise_template': {value: null},
      },
      templateTypes: [
        "sms",
        "email",
        "both"
      ],
      loadingRecommendedTemplateData: false,
      recommendedTemplateSearch: '',
      selectedRecommendedTemplates: [],
      recommendedTemplateData:{},
      displayAddEditTemplateRecommendedModal: false,
      recommendedTemplateAddEditMode: 'create',
      recommendedTemplatesDataError: '',
      recommendedTemplateFilters: {
        'name': {value: null},
        'type': {value: null},
        'body': {value: null}
      },
      currentRecommendedTemplates: [],
      paginateGetRecommendedTemplates: true,
      selectingAllMatchingRecommendedTemplates: false,
      globalTemplateData: [],
      globalTemplateDataError: '',
      loadingGlobalTemplateData: false,
      globalTemplateAddEditMode: 'create',
      globalTemplateFilters: {
        'name': {value: null},
        'type': {value: null},
        'body': {value: null},
        'active': {value: null},
      },
      globalTemplateTypes: [
        "sms",
        "email",
        "both"
      ],
      displayAddEditGlobalTemplateModal: false,
      currentGlobalTemplates: [],
      modules: [],
      loadingModules: false,
      company_search: '',
      login_search: '',
      confirm: useConfirm(),
      // uploads dashboard variables
      expandedProPharmaciesRow: [], // expanded rows for the faulty ETL pharmacies
      faultyEtlPharmacyData: [], // data for the faulty ETL pharmacies
      loadingFaultyEtlPharmacyData: false, // flag to show the loading 
      faultyEtlPharmacyDataError: '', // error message for the faulty ETL pharmacies
      etlStatsData: {}, // data for the upload stats summary
      uploadsPieChartData: {}, // data for the pie chart
      uploadsPieChartOptions: {}, // options for the pie chart
      periods: [
        'Business Days',
        'Hours'
      ], // time periods for the faulty uploads in the uploads dashboard data table to be displayed in the drop down
      locationEtlQueueInitialRefresh: false, // flag to refresh the ETL queue data in the location ETL modal
      // selectedPeriodTypeNoUploadsLocal: 'Business Days', // local default value for the time period to check for error uploads
      // selectedInputNoUploadsLocal: 4, // local default value for the time period to check for error uploads
      selectedPeriodTypeNoUploads: 'Business Days', // default value for the time period to check for error uploads
      selectedInputNoUploads: 4, // default value for the time period to check for error uploads
      uploadStatuses : [], // statuses for the faulty uploads in the uploads dashboard data table
      faultyUploadFilters : {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        'pharmacy.name': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'status': { value: null, matchMode: FilterMatchMode.IN },
      }, // filters for faulty uploads in the uploads dashboard data table
      renderUploadChart: false, // flag to render the chart
      campaign_search: '',
      templatesTabTypeSelection: 'recommended',
      campaignsTabTypeSelection: 'recommended',
      templatesTabTypeOptions: [
       {key: 'Recommended', value: 'recommended'},
       {key:'Company', value: 'company'},
       {key: 'Global', value: 'global'}
      ],
      campaignsTabTypeOptions: [
       {key: 'Recommended', value: 'recommended'},
       {key:'Company', value: 'company'},
      ],
      goalOptions : ['revenue', 
        'adherence', 
        'loyalty', 
        'scripts'
      ],
      campaignsData: {},
      campaignsDataError: '',
      selectedCampaigns: [],
      loadingCampaignsData: false,
      paginateGetCampaigns: true,
      selectingAllMatchingCampaigns: false,
      campaignFilters: {
        'name': {value: null},
        'company.name': {value: null},
        'goal_type': {value: null},
        'active': {value:null}
      },
      displayAddEditCampaignModal: false,
      currentCampaigns: [],
      campaignAddEditMode: 'create',
      templatesToBeUpdated: [],
      campaignsToBeUpdated: [],
      loadingCampaignAffectedTemplates: false,
      loadingTemplateAffectedCampaigns: false,
      recommendedCampaignFilters: {
        'name': {value: null},
        'description': {value: null},
        'goal_type': {value: null},
      },
      paginateGetRecommendedCampaigns: true,
      selectingAllMatchingRecommendedCampaigns: false,
      recommendedCampaignsData: {},
      selectedRecommendedCampaigns: [],
      recommendedCampaignsDataError: '',
      loadingRecommendedCampaignsData: false,
      displayAddEditRecommendedCampaignModal: false,
      currentRecommendedCampaigns: [],
      recommendedCampaignAddEditMode: 'create',
      clinical_trial_search:'',
      clinicalTrialsData: [],
      clinicalTrialsDataError: '',
      loadingClinicalTrialsData: false,
      expandedClinicalTrialsRows: [],
      loadingExpandedClinicalTrialsRows: [],
      displayAddEditClinicalTrialModal: false,
      clinicalTrialAddEditMode: 'create',
      selectedClinicalTrial: {},
      displayAddEditRecruiterProviderModal: false,
      displayActivateDeactivateClinicalTrialConfirmationModal: false,
      recruiterProviderAddEditMode: 'create',
      selectedRecruiterProvider: {},
      currentDate : new Date(),
    }
  },
  computed: {
    /**
     * 
     */
    clinicalTrialAction() {
      return this.selectedClinicalTrial.active ? 'activate' : 'deactivate';
    },
    /**
     * Returns the payload for the template search request
     * @returns {Object} - The payload for the template search request
     */
    templateSearchPayload() {
      let activeStatus = this.templateFilters['active'].value;
      if (activeStatus === 'active') {
        activeStatus = true;
      } else if (activeStatus === 'inactive') {
        activeStatus = false;
      }

      let enterprise_template = this.templateFilters['enterprise_template'].value;
      if (enterprise_template === 'enterprise') {
        enterprise_template = true;
      } else if (enterprise_template === 'pharmacy') {
        enterprise_template = false;
      }

      return {
        "page": this.$store.state.templatePage || 1,
        "company": this.templateFilters['company.name'].value,
        "name": this.templateFilters['name'].value,
        "type": this.templateFilters['type'].value,
        "body": this.templateFilters['body'].value,
        "active": activeStatus,
        "enterprise_template": enterprise_template,
        "global_template": false,
        "paginate": this.paginateGetTemplates
      }
    },
    /**
     * Returns the payload for the template search request
     * @returns {Object} - The payload for the template search request
     */
     globalTemplateSearchPayload() {
      let activeStatus = this.templateFilters['active'].value;
      if (activeStatus === 'active') {
        activeStatus = true;
      } else if (activeStatus === 'inactive') {
        activeStatus = false;
      }

      let enterprise_template = false;

      return {
        "page": this.$store.state.globalTemplatePage || 1,
        "name": this.globalTemplateFilters['name'].value,
        "type": this.globalTemplateFilters['type'].value,
        "body": this.globalTemplateFilters['body'].value,
        "active": activeStatus,
        "enterprise_template": enterprise_template,
        "global_template": true,
        "paginate": this.paginateGetTemplates
      }
    },
    /**
     * Returns the menu items for the template menu
     * @returns {Array} - The menu items for the template menu
     */
    templateMenuItems() {
      return [
        {
          key: 'create_template',
          label: 'Create Template',
          icon: 'pi pi-plus',
          command: () => {
            this.openAddTemplateModal();
          },
          disabled: false
        },
        {
          key: 'edit_template',
          label: 'Batch Edit',
          icon: 'pi pi-pencil',
          command: () => {
            this.openConfirmTemplateEdit(this.selectedTemplates);
          },
          disabled: !Array.isArray(this.selectedTemplates) || this.selectedTemplates.length === 0 // Disable the edit button if no templates are selected
        },
        {
          key: 'delete_template',
          label: 'Batch Delete',
          icon: 'pi pi-trash',
          command: () => {
            this.openConfirmTemplateDeletion(this.selectedTemplates);
          },
          disabled: !Array.isArray(this.selectedTemplates) || this.selectedTemplates.length === 0 // Disable the delete button if no templates are selected
        },
        {
          key: 'activate_template_status',
          label: 'Batch Activate',
          icon: 'pi pi-check',
          command: () => {
            this.openConfirmActivateDeactivateTemplateModal(this.selectedTemplates, 'activate');
          },
          disabled: !Array.isArray(this.selectedTemplates) || this.selectedTemplates.length === 0 // Disable the edit button if no templates are selected
        },
        {
          key: 'deactivate_template_status',
          label: 'Batch Deactivate',
          icon: 'pi pi-times',
          command: () => {
            this.openConfirmActivateDeactivateTemplateModal(this.selectedTemplates, 'deactivate');
          },
          disabled: !Array.isArray(this.selectedTemplates) || this.selectedTemplates.length === 0 // Disable the edit button if no templates are selected
        },
      ];
    },
    /**
     * Returns the menu items for the recommended template menu
     * @returns {Array} - The menu items for the recommended template menu
     */
      recommendedTemplateMenuItems() {
      return [
        {
          key: 'create_recommended_template',
          label: 'Create Template',
          icon: 'pi pi-plus',
          command: () => {
            this.openAddRecommendedTemplateModal();
          },
          disabled: false
        },
        {
          key: 'edit_recommended_template',
          label: 'Batch Edit',
          icon: 'pi pi-pencil',
          command: () => {
            this.openConfirmTemplateEdit(this.selectedRecommendedTemplates);
          },
          disabled: !Array.isArray(this.selectedRecommendedTemplates) || this.selectedRecommendedTemplates.length === 0 // Disable the edit button if no templates are selected
        },
        {
          key: 'delete_recommended_template',
          label: 'Batch Delete',
          icon: 'pi pi-trash',
          command: () => {
            this.openConfirmTemplateDeletion(this.selectedRecommendedTemplates);
          },
          disabled: !Array.isArray(this.selectedRecommendedTemplates) || this.selectedRecommendedTemplates.length === 0 // Disable the delete button if no templates are selected
        }
      ];
    },
    /**
     * Returns the menu items for the campaign menu
     * @returns {Array} - The menu items for the campaign menu
     */
     campaignMenuItems() {
      return [
        {
          key: 'create_campaign',
          label: 'Create Campaign',
          icon: 'pi pi-plus',
          command: () => {
            this.openAddCampaignModal();
          },
          disabled: false
        },
        {
          key: 'edit_campaign',
          label: 'Batch Edit',
          icon: 'pi pi-pencil',
          command: () => {
            this.openConfirmCampaignEdit(this.selectedCampaigns);
          },
          disabled: !Array.isArray(this.selectedCampaigns) || this.selectedCampaigns.length === 0 // Disable the edit button if no campaigns are selected
        },
        {
          key: 'delete_template',
          label: 'Batch Delete',
          icon: 'pi pi-trash',
          command: () => {
            this.openConfirmCampaignDeletion(this.selectedCampaigns);
          },
          disabled: !Array.isArray(this.selectedCampaigns) || this.selectedCampaigns.length === 0 // Disable the delete button if no campaigns are selected
        },
        {
          key: 'activate_campaign_status',
          label: 'Batch Activate',
          icon: 'pi pi-check',
          command: () => {
            this.openConfirmActivateDeactivateCampaignModal(this.selectedCampaigns, 'activate');
          },
          disabled: !Array.isArray(this.selectedCampaigns) || this.selectedCampaigns.length === 0 // Disable the edit button if no templates are selected
        },
        {
          key: 'deactivate_campaign_status',
          label: 'Batch Deactivate',
          icon: 'pi pi-times',
          command: () => {
            this.openConfirmActivateDeactivateCampaignModal(this.selectedCampaigns, 'dectivate');
          },
          disabled: !Array.isArray(this.selectedCampaigns) || this.selectedCampaigns.length === 0 // Disable the edit button if no templates are selected
        },
      ];
    },
    /**
     * Returns the menu items for the recommended campaign menu
     * @returns {Array} - The menu items for the recommended campaign menu
     */
     recommendedCampaignMenuItems() {
      return [
        {
          key: 'create_recommended_campaign',
          label: 'Create Campaign',
          icon: 'pi pi-plus',
          command: () => {
            this.openAddRecommendedCampaignModal();
          },
          disabled: false
        },
        {
          key: 'edit_recommended_campaign',
          label: 'Batch Edit',
          icon: 'pi pi-pencil',
          command: () => {
            this.openConfirmCampaignEdit(this.selectedRecommendedCampaigns);
          },
          disabled: !Array.isArray(this.selectedRecommendedCampaigns) || this.selectedRecommendedCampaigns.length === 0 // Disable the edit button if no recommended campaigns are selected
        },
        {
          key: 'delete_recommended_template',
          label: 'Batch Delete',
          icon: 'pi pi-trash',
          command: () => {
            this.openConfirmCampaignDeletion(this.selectedRecommendedCampaigns);
          },
          disabled: !Array.isArray(this.selectedRecommendedCampaigns) || this.selectedRecommendedCampaigns.length === 0 // Disable the delete button if no recommended campaigns are selected
        }
      ];
    },
    /**
     * Returns the status of the select all checkbox
     * Returns true if all templates are selected, false otherwise
     * @returns {Boolean} - The status of the select all checkbox
     */
    selectAllTemplates() {
      if (this.loadingTemplateData || !Array.isArray(this.selectedTemplates) || this.selectedTemplates.length === 0) {
        return false;
      }
      
      return this.templateData.results.every(template => this.selectedTemplates.includes(template));
    },
    /**
     * Returns the status of the select all checkbox
     * Returns true if all templates are selected, false otherwise
     * @returns {Boolean} - The status of the select all checkbox
     */
     selectAllRecommendedTemplates() {
      if (this.loadingRecommendedTemplateData || !Array.isArray(this.selectedRecommendedTemplates) || this.selectedRecommendedTemplates.length === 0) {
        return false;
      }
      
      return this.recommendedTemplateData.templates.every(template => this.selectedRecommendedTemplates.includes(template));
    },
    /**
     * Returns the status of the select all checkbox
     * Returns true if all campaigns are selected, false otherwise
     * @returns {Boolean} - The status of the select all checkbox
     */
     selectAllRecommendedCampaigns() {
      if (this.loadingRecommendedCampaignsData || !Array.isArray(this.selectedRecommendedCampaigns) || this.selectedRecommendedCampaigns.length === 0) {
        return false;
      }
      
      return this.recommendedCampaignsData.campaigns.every(campaign => this.selectedRecommendedCampaigns.includes(campaign));
    },
    /**
     * Returns the status of the select all checkbox
     * Returns true if all campaigns are selected, false otherwise
     * @returns {Boolean} - The status of the select all checkbox
     */
     selectAllCampaigns() {
      if (this.loadingCampaignsData || !Array.isArray(this.selectedCampaigns) || this.selectedCampaigns.length === 0) {
        return false;
      }
      
      return this.campaignsData.campaigns.every(campaign => this.selectedCampaigns.includes(campaign));
    },
     /**
     * Returns the payload for the ETL Queue request with Pharmacies with faulty uploads in a given time
     * @returns {Object} - The list request payload for the Faulty ETL Queues for Pro Pharmacies 
     */
     proPharmacyListPayload() {
      var filters = {"show_no_uploads": true};
      if (this.selectedPeriodTypeNoUploads === "Hours") {
        filters['hours'] = this.selectedInputNoUploads;
      }
      else {
        filters['business_days'] = this.selectedInputNoUploads;
      }
      return {
        module: "strong_pro",
        filters:filters

      }
    },
    /**
     * Returns the payload for the recommended campaigns request
     * @returns {Object} - The payload for the recommended campaigns request
     */
     recommendedTemplatesPayload() {
      return {
        "page": this.$store.state.recommendedTemplatePage || 1,
        "name": this.recommendedTemplateFilters['name'].value,
        "type": this.recommendedTemplateFilters['type'].value,
        "body": this.recommendedTemplateFilters['body'].value,
        "paginate": this.paginateGetRecommendedTemplates
      }
    },
    /**
     * Returns the payload for the campaigns search request
     * @returns {Object} - The payload for the campaigns search request
     */
     campaignSearchPayload() {
      let activeStatus = this.campaignFilters['active'].value;
      if (activeStatus === 'active') {
        activeStatus = true;
      } else if (activeStatus === 'inactive') {
        activeStatus = false;
      }

      return {
        "name": this.campaignFilters['name'].value,
        "company": this.campaignFilters['company.name'].value,
        "goal_type": this.campaignFilters['goal_type'].value,
        "active": activeStatus,
        "page": this.$store.state.campaignsPage || 1,
        "paginate": this.paginateGetCampaigns
      }
    },
    /**
     * Returns the payload for the recommended campaigns request
     * @returns {Object} - The payload for the recommended campaigns request
     */
    recommendedCampaignsPayload() {
      return {
        "page": this.$store.state.recommendedCampaignsPage || 1,
        "name": this.recommendedCampaignFilters['name'].value,
        "description": this.recommendedCampaignFilters['description'].value,
        "goal_type": this.recommendedCampaignFilters['goal_type'].value,
        "paginate": this.paginateGetRecommendedCampaigns
      }
    },
    /**
     * Returns the payload for the activate/deactivate clinical trial request
     */
    activateDeactivateClinicalTrialPayload() {
      let currentDateTime = this.currentDate;
      let activate = !this.selectedClinicalTrial.active;
      let start_date = null
      let end_date = null
      // If the clinical trial is being activated, set the start date to today and end date to null
      if (activate) {
        currentDateTime.setHours(0, 0, 0, 0);
        start_date = new Date(currentDateTime.getTime());
        end_date = null;
      } 
      else {
        currentDateTime.setHours(23, 59, 59);
        end_date = new Date(currentDateTime.getTime());
      }
      return {
        "active": activate,
        "notify_pharmacies": this.selectedClinicalTrial.notifyPharmacies,
        "cancel_messages": this.selectedClinicalTrial.cancelMessages,
        "start_date": start_date,
        "end_date": end_date
      }
    }
  },
  watch: {
  },
  async mounted() {
    await Promise.all([
      this.getModules(),
      this.getCompanies(),
      this.getUsers(),
      this.getTemplates(),
      this.getCampaigns(),
      this.getRecommendedCampaigns(), // function to fetch recommended campaigns for the campaigns tab
      this.getRecommendedTemplates(), // function to fetch recommended templates for the templates tab
      this.getGlobalTemplates(), // function to fetch global templates for the templates tab
      this.getFaultyEtlItems(), // function to fetch pharmcies with faulty ETL items for uploads dashboard
      this.getClinicalTrials() // function to fetch clinical trials data
    ]);
  },
  methods: {
    /**
     * Get the companies
     * @returns {Promise} - The promise for the companies
     */
    async getCompanies() {
      this.$store.dispatch('getRefreshToken');
      this.companiesData = [];
      this.companiesDataError = '';
      this.loadingCompaniesData = true;

      try {
        const res = await axios.post('/api/get-companies', { "page": this.$store.state.page || 1 , "name": this.company_search }, this.$store.state.header);
        this.handleCompaniesSuccess(res);
      } catch (err) {
        this.handleCompaniesError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle the success response for the companies
     * @param {Object} res - The response object
     */
    handleCompaniesSuccess(res) {
      console.log('The companies response is: ', res.data);
      this.companiesData = res.data;
      this.companiesData.results.forEach(company => {
        this.expandedCompaniesRows.push({
          [company.uuid]: false
        })
      })
      this.loadingCompaniesData = false;
    },
    /**
     * Handle the error response for the companies
     * @param {Object} err - The error object
     */
    handleCompaniesError(err) {
      console.log('The companies error is: ', err);
      this.loadingCompaniesData = false;
      this.companiesDataError = err;
    },
    /**
     * Get the modules
     * @returns {Promise} - The promise for the modules
     */
    async getModules() {
      this.$store.dispatch('getRefreshToken');
      this.modules = [];
      this.loadingModules = true;
      try {
        const res = await axios.post('/api/modules', {}, this.$store.state.header);
        this.handleModulesSuccess(res);
      } catch (err) {
        this.handleModulesError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle the success response for the modules
     * @param {Object} res - The response object
     */
    handleModulesSuccess(res) {
      console.log('The modules response is: ', res.data);
      this.modules = res.data.data;
      this.loadingModules = false;
    },
    /**
     * Handle the error response for the modules
     * @param {Object} err - The error object
     */
    handleModulesError(err) {
      console.log('The modules error is: ', err);
      this.loadingModules = false;
    },
    /**
     * Handle the page refresh for the company list
     * @param integer page - The page number
     */
     displayPage(page) {
      this.$store.state.page = page;
      this.getCompanies();
    },
    /**
     * Handle the page refresh for the users list
     * @param integer page - The page number
     */
    displayUserPage(page) {
      this.$store.state.userPage = page;
      this.getUsers();
    },
    /**
     * Handle the page refresh for the template list
     * @param integer page - The page number
     */
     displayTemplatePage(page) {
      this.$store.state.templatePage = page;
      this.getTemplates();
    },
    /** 
     * Handle the page refresh for the recommended templates list
     * @param integer page - The page number
     */
     displayRecommendedTemplatePage(page) {
      this.$store.state.recommendedTemplatePage = page;
      this.getRecommendedTemplates();
    },
    /**
     * Handle the page refresh for the global template list
     * @param integer page - The page number
     */
     displayGlobalTemplatePage(page) {
      this.$store.state.globalTemplatePage = page;
      this.getGlobalTemplates();
    },
    /** 
     * Handle the page refresh for the campaign list
     * @param integer page - The page number
     */
     displayCampaignsPage(page) {
      this.$store.state.campaignsPage = page;
      this.getCampaigns();
    },
    /**
     * Handle the page refresh for the recommended campaigns list
     * @param integer page - The page number
     */
    displayRecommendedCampaignsPage(page) {
      this.$store.state.recommendedCampaignsPage = page;
      this.getRecommendedCampaigns();
    },
    /**
     * Handle the page refresh for the clinical trials list
     * @param integer page - The page number
     */
     displayClinicalTrialsPage(page) {
      this.$store.state.clinicalTrialsPage = page;
      this.getClinicalTrials();
    },
    /**
     * Get the users
     * @returns {Promise<void>} - The promise for the users
     */
    async getUsers() {
      this.$store.dispatch('getRefreshToken');
      this.usersData = [];
      this.usersDataError = '';
      this.loadingUsersData = true;
      try {
        const res = await axios.post('/api/get-users', { "page": this.$store.state.userPage || 1, "name": this.login_search }, this.$store.state.header);
        this.handleUsersSuccess(res);
      } catch (err) {
        this.handleUsersError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle the success response for the users
     * @param {Object} res - The response object
     */
    handleUsersSuccess(res) {
      console.log('The users response is: ', res.data);
      this.usersData = res.data;
      this.usersData.results.forEach(user => {
        this.expandedUsersRows.push({
          [user.uuid]: false
        })
      })
      this.loadingUsersData = false;
    },
    /**
     * Handle the error response for the users
     * @param {Object} err - The error object
     */
    handleUsersError(err) {
      console.log('The users error is: ', err);
      this.loadingUsersData = false;
      this.usersDataError = err;
    },
    /**
     * Remove the company
     * @param {Promise} - The promise for the company
     */
    async removeCompany(company) {
      console.log('The company to remove is: ', company);
      this.$store.dispatch('getRefreshToken');
      this.loadingCompaniesData = true;

      try {
        const res = await axios.post(`/api/company/${company.uuid}/remove`, {}, this.$store.state.header);
        this.handleRemoveCompanySuccess(res);
      } catch (err) {
        this.handleRemoveCompanyError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle the success response for removing the company
     * @param {Object} res - The response object
     */
    handleRemoveCompanySuccess(res) {
      console.log('The remove company response is: ', res.data);
      this.loadingCompaniesData = false;
      this.getCompanies();
    },
    /**
     * Handle the error response for removing the company
     * @param {Object} err - The error object
     */
    handleRemoveCompanyError(err) {
      console.log('The remove company error is: ', err);
      this.loadingCompaniesData = false;
    },
    /**
     * Get the templates
     * @returns {Promise} - The promise for the templates
     */
    async getTemplates() {
      this.$store.dispatch('getRefreshToken');
      this.templateData = [];
      this.templateDataError = '';
      this.paginateGetTemplates = true;
      this.loadingTemplateData = true;

      try {
        const res = await axios.post('/api/template/admin/search', this.templateSearchPayload, this.$store.state.header);
        this.handleTemplatesSuccess(res);
      } catch (err) {
        this.handleTemplatesError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle the success response for the templates
     * @param {Object} res - The response object
     */
    handleTemplatesSuccess(res) {
      console.log('The templates response is: ', res.data);
      this.templateData = res.data;
      this.loadingTemplateData = false;
    },
    /**
     * Handle the error response for the templates
     * @param {Object} err - The error object
     */
    handleTemplatesError(err) {
      console.log('The templates error is: ', err);
      this.loadingTemplateData = false;
      this.templateDataError = err;
    },
    /**
     * Get All Templates
     * @returns {Promise} - The promise for the templates
     */
     async getAllTemplates() {
      this.$store.dispatch('getRefreshToken');
      this.paginateGetTemplates = false;
      this.selectingAllMatchingTemplates = true;

      try {
        const res = await axios.post('/api/template/admin/search', this.templateSearchPayload, this.$store.state.header);
        this.handleAllTemplatesSuccess(res);
      } catch (err) {
        this.handleAllTemplatesError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle the success response for the templates
     * @param {Object} res - The response object
     */
    handleAllTemplatesSuccess(res) {
      console.log('The select all templates response is: ', res.data);
      this.selectedTemplates = res.data.results;
      this.selectingAllMatchingTemplates = false;
    },
    /**
     * Handle the error response for the templates
     * @param {Object} err - The error object
     */
    handleAllTemplatesError(err) {
      console.log('The select all templates error is: ', err);
      this.selectingAllMatchingTemplates = false;
    },
    /**
     * Activate or deactivate the batch of templates
     * @param {Array} templates - The templates to activate or deactivate
     * @param {Boolean} active - The active status
     * @returns {Promise} - The promise for the activate/deactivate templates
     */
    activateDeactivateTemplates(templates, active=null) {
        this.$store.dispatch('getRefreshToken');
        let templateIds = templates.map(template => template.uuid);
        let payload = {
          template_ids: templateIds,
          active: active
        }
        this.loadingTemplateData = true;
        axios.post(`/api/template/admin/edit-active-status`, payload, this.$store.state.header)
        .then(res => {
          this.handleActivateDeactivateTemplatesSuccess(res);
        })
        .catch(err => {
          this.handleActivateDeactivateTemplatesError(this.handleAxiosError(err));
        })
      },
      /**
       * Handle the success response for activating/deactivating the template
       * @param {Object} res - The response object
       */
      handleActivateDeactivateTemplatesSuccess(res) {
        console.log('The activate/deactivate template response is: ', res.data);
        this.loadingTemplateData = false;
        if (this.templatesTabTypeSelection === 'company') {
          this.getTemplates();
        } else {
          this.getGlobalTemplates();
        }
        this.getTemplates();
      },
      /**
       * Handle the error response for activating/deactivating the campaign
       * @param {Object} err - The error object
       */
       handleActivateDeactivateTemplatesError(err) {
        console.log('The activate/deactivate template error is: ', err);
        this.templatesDataError = err;
        this.loadingTemplateData = false;
      },
    /**
     * Get the campaign names that will be updated in the batch activation/deactivation of templates
     * @param {Array} templates - The templates to edit
     * @param {String} active - The active status
     */
    async getTemplateAffectedCampaigns(templates, active) {
      this.$store.dispatch('getRefreshToken');
      this.campaignsToBeUpdated = [];
      this.loadingTemplateAffectedCampaigns = true;
      let payload = {
        template_ids: templates.map(template => template.uuid),
        active: active
      }
      try {
        const res = await axios.post(`/api/template/admin/affected-campaigns`, payload, this.$store.state.header);
        this.handleGetTemplateAffectedCampaignsSuccess(res);
      } catch (err) {
        this.handleGetTemplateAffectedCampaignsError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle the success response for getting the campaign names that will be updated in the batch activation/deactivation of templates
     * @param {Object} res - The response object
     */
     handleGetTemplateAffectedCampaignsSuccess(res) {
      console.log('The affected campaigns status response is: ', res.data);
      this.campaignsToBeUpdated = res.data.campaign_names;
      this.loadingTemplateAffectedCampaigns = false;
    },
    /**
     * Handle the error response for getting the campaign names that will be updated in the batch activation/deactivation of templates
     * @param {Object} err - The error object
     */
     handleGetTemplateAffectedCampaignsError(err) {
      console.log('The affected campaigns status error is: ', err);
      this.loadingTemplateAffectedCampaigns = false;
    },
    /**
     * Get All Recommended Templates
     * @returns {Promise} - The promise for the Recommended templates
     */
     async getAllRecommendedTemplates() {
      this.$store.dispatch('getRefreshToken');
      this.paginateGetRecommendedTemplates = false;
      this.selectingAllMatchingRecommendedTemplates = true;

      try {
        const res = await axios.post('/api/template-recommendation/admin/search', this.recommendedTemplatesPayload, this.$store.state.header);
        this.handleAllRecommendedTemplatesSuccess(res);
      } catch (err) {
        this.handleAllRecommendedTemplatesError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle the success response for the recommended templates
     * @param {Object} res - The response object
     */
    handleAllRecommendedTemplatesSuccess(res) {
      console.log('The select all recommended templates response is: ', res.data);
      this.selectedRecommendedTemplates = res.data.templates;
      this.selectingAllMatchingRecommendedTemplates = false;
    },
    /**
     * Handle the error response for the recommended templates
     * @param {Object} err - The error object
     */
     handleAllRecommendedTemplatesError(err) {
      console.log('The select all recommended templates error is: ', err);
      this.selectingAllMatchingRecommendedTemplates = false;
    },
    /**
     * Get the global templates
     * @returns {Promise} - The promise for the templates
     */
     async getGlobalTemplates() {
      this.$store.dispatch('getRefreshToken');
      this.globalTemplateData = [];
      this.globalTemplateDataError = '';
      this.loadingGlobalTemplateData = true;

      try {
        const res = await axios.post('/api/template/admin/search', this.globalTemplateSearchPayload, this.$store.state.header);
        this.handleGlobalTemplatesSuccess(res);
      } catch (err) {
        this.handleGlobalTemplatesError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle the success response for the templates
     * @param {Object} res - The response object
     */
    handleGlobalTemplatesSuccess(res) {
      console.log('The global templates response is: ', res.data);
      this.globalTemplateData = res.data;
      this.loadingGlobalTemplateData = false;
    },
    /**
     * Handle the error response for the templates
     * @param {Object} err - The error object
     */
    handleGlobalTemplatesError(err) {
      console.log('The templates error is: ', err);
      this.loadingGlobalTemplateData = false;
      this.globalTemplateDataError = err;
    },
    /**
     * Get All Recommended campaigns
     * @returns {Promise} - The promise for the Recommended campaigns
     */
     async getAllRecommendedCampaigns() {
      this.$store.dispatch('getRefreshToken');
      this.paginateGetRecommendedCampaigns = false;
      this.selectingAllMatchingRecommendedCampaigns = true;

      try {
        const res = await axios.post('/api/campaign-recommendation/admin/search', this.recommendedCampaignsPayload, this.$store.state.header);
        this.handleAllRecommendedCampaignsSuccess(res);
      } catch (err) {
        this.handleAllRecommendedCampaignsError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle the success response for the recommended campaigns
     * @param {Object} res - The response object
     */
    handleAllRecommendedCampaignsSuccess(res) {
      console.log('The select all recommended campaigns response is: ', res.data);
      this.selectedRecommendedCampaigns = res.data.campaigns;
      this.selectingAllMatchingRecommendedCampaigns = false;
    },
    /**
     * Handle the error response for the recommended campaigns
     * @param {Object} err - The error object
     */
     handleAllRecommendedCampaignsError(err) {
      console.log('The select all recommended campaigns error is: ', err);
      this.selectingAllMatchingRecommendedCampaigns = false;
    },
    /**
     * Get All campaigns
     * @returns {Promise} - The promise for the campaigns
     */
     async getAllCampaigns() {
      this.$store.dispatch('getRefreshToken');
      this.paginateGetCampaigns = false;
      this.selectingAllMatchingCampaigns = true;

      try {
        const res = await axios.post('/api/campaign/admin/search', this.campaignSearchPayload, this.$store.state.header);
        this.handleAllCampaignsSuccess(res);
      } catch (err) {
        this.handleAllCampaignsError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle the success response for the campaigns
     * @param {Object} res - The response object
     */
    handleAllCampaignsSuccess(res) {
      console.log('The select all campaigns response is: ', res.data);
      this.selectedCampaigns = res.data.campaigns;
      this.selectingAllMatchingCampaigns = false;
    },
    /**
     * Handle the error response for the campaigns
     * @param {Object} err - The error object
     */
     handleAllCampaignsError(err) {
      console.log('The select all campaigns error is: ', err);
      this.selectingAllMatchingCampaigns = false;
    },
    // Event to handle filter event
    onFilterTemplate() {
      if (this.templatesTabTypeSelection === 'company') {
        this.getTemplates();
      } else if (this.templatesTabTypeSelection === 'global') {
        this.getGlobalTemplates();
      } else {
        this.getRecommendedTemplates();
      }
    },
    // Event to handle filter event
    onFilterCampaign() {
      if (this.campaignsTabTypeSelection === 'company') {
        this.getCampaigns();
      } else {
        this.getRecommendedCampaigns();
      }
    },
    /**
     * On select all changing, update the selectedTemplates array
     * @param {Object} event 
     */
    onSelectAllTemplatesChange(event) {
      const selectAll = event.checked;
      var currentIds = null;

      // If selecting all templates, add the current page of templates to selectedTemplates, removing duplicates
      if (selectAll) {
        currentIds = new Set(this.selectedTemplates.map(template => template.uuid));
        var mergedIds = [...this.selectedTemplates, ...this.templateData.results.filter(template => !currentIds.has(template.uuid))]
        this.selectedTemplates = mergedIds;
      }
      // If unselecting all displayed templates, remove the current page of templates from selectedTemplates
      else {
        currentIds = new Set(this.templateData.results.map(template => template.uuid));
        var filteredIds = this.selectedTemplates.filter(template => !currentIds.has(template.uuid))
        this.selectedTemplates = filteredIds;
      }
    },
    /**
     * On select all changing, update the selectedRecommendedTemplates array
     * @param {Object} event 
     */
     onSelectAllRecommendedTemplatesChange(event) {
      const selectAll = event.checked;
      var currentIds = null;

      // If selecting all recommended templates, add the current page of templates to selectedRecommendedTemplates, removing duplicates
      if (selectAll) {
        currentIds = new Set(this.selectedRecommendedTemplates.map(template => template.template_recommendation_id));
        var mergedIds = [...this.selectedRecommendedTemplates, ...this.recommendedTemplateData.templates.filter(template => !currentIds.has(template.template_recommendation_id))]
        this.selectedRecommendedTemplates = mergedIds;
      }
      // If unselecting all displayed recommended templates, remove the current page of templates from selectedRecommendedTemplates
      else {
        currentIds = new Set(this.recommendedTemplateData.templates.map(template => template.template_recommendation_id));
        var filteredIds = this.selectedRecommendedTemplates.filter(template => !currentIds.has(template.template_recommendation_id))
        this.selectedRecommendedTemplates = filteredIds;
      }
    },
    /**
     * On select all changing, update the selectedRecommendedCampaigns array
     * @param {Object} event 
     */
     onSelectAllRecommendedCampaignsChange(event) {
      const selectAll = event.checked;
      var currentIds = null;

      // If selecting all recommended campaigns, add the current page of campaigns to selectedRecommendedCampaigns removing duplicates
      if (selectAll) {
        currentIds = new Set(this.selectedRecommendedCampaigns.map(campaign => campaign.campaign_recommendation_id));
        var mergedIds = [...this.selectedRecommendedCampaigns, ...this.recommendedCampaignsData.campaigns.filter(campaign => !currentIds.has(campaign.campaign_recommendation_id))]
        this.selectedRecommendedCampaigns = mergedIds;
      }
      // If unselecting all displayed recommended Campaigns, remove the current page of Campaigns from selectedRecommendedCampaigns
      else {
        currentIds = new Set(this.recommendedCampaignData.campaigns.map(campaign => campaign.campaign_recommendation_id));
        var filteredIds = this.selectedRecommendedCampaigns.filter(campaign => !currentIds.has(campaign.campaign_recommendation_id))
        this.selectedRecommendedCampaigns = filteredIds;
      }
    },
    /**
     * On select all changing, update the selectedCampaigns array
     * @param {Object} event 
     */
     onSelectAllCampaignsChange(event) {
      const selectAll = event.checked;
      var currentIds = null;

      // If selecting all campaigns, add the current page of campaigns to selectedCampaigns removing duplicates
      if (selectAll) {
        currentIds = new Set(this.selectedCampaigns.map(campaign => campaign.campaign_id));
        var mergedIds = [...this.selectedCampaigns, ...this.CampaignsData.campaigns.filter(campaign => !currentIds.has(campaign.campaign_id))]
        this.selectedCampaigns = mergedIds;
      }
      // If unselecting all displayed  Campaigns, remove the current page of Campaigns from selectedCampaigns
      else {
        currentIds = new Set(this.CampaignData.campaigns.map(campaign => campaign.campaign_recommendation_id));
        var filteredIds = this.selectedCampaigns.filter(campaign => !currentIds.has(campaign.campaign_recommendation_id))
        this.selectedCampaigns = filteredIds;
      }
    },
    /**
     * Toggle the template menu visibility
     * @param {Object} event - The event object
     */
    toggleTemplateMenu(event) {
      this.$store.dispatch('getRefreshToken');
      this.$refs.template_menu.toggle(event);
    },
    /**
     * Toggle the Campaign menu visibility
     * @param {Object} event - The event object
     */
     toggleCampaignMenu(event) {
      this.$store.dispatch('getRefreshToken');
      this.$refs.campaign_menu.toggle(event);
    },
    /**
     * Refresh the templates on a successful edit
     */
    templateEdited() {
      this.selectedTemplates = [];
      this.getTemplates();
    },
    /**
     * Refresh the templates on a successful create
     */
    templateCreated() {
      this.getTemplates();
    },
    /**
     * Refresh the recommended templates on a successful edit
     */
     recommendedTemplateEdited() {
      this.selectedRecommendedTemplates = [];
      this.getRecommendedTemplates();
    },
    /**
     * Refresh the recommended templates on a successful create
     */
    recommendedTemplateCreated() {
      this.getRecommendedTemplates();
    },
    /**
     * Refresh the global templates on a successful edit
     */
     globalTemplateEdited() {
      this.getGlobalTemplates();
      this.getClinicalTrials();
    },
    /**
     * Refresh the global templates on a successful create
     */
    globalTemplateCreated() {
      this.getGlobalTemplates();
    },
    /**
     * Refresh the clinical trials on a successful create
     */
    clinicalTrialCreated() {
      this.getClinicalTrials();
    },
    /**
     * Refresh the clinical trials after a successful edit
     */
    clinicalTrialEdited() {
      this.getClinicalTrials();
      this.selectedClinicalTrial = {};
    },
    /**
     * Refresh the clinical trials on a successful recruiter/provider create
     */
    recruiterProviderCreated() {
      this.getClinicalTrials();
    },
    /**
     * Refresh the clinical trials on a successful recruiter/provider edit
     */
    recruiterProviderEdited() {
      this.selectedRecruiterProvider = {};
      this.getClinicalTrials();
    },
    /**
     * Refresh the recommended campaigns on a successful edit
     */
     campaignEdited() {
      this.selectedCampaigns = [];
      this.getCampaigns();
    },
    /**
     * Refresh the recommended on a successful create
     */
    campaignCreated() {
      this.getCampaigns();
    },
    /**
     * Refresh the recommended campaigns on a successful edit
     */
     recommendedCampaignEdited() {
      this.selectedRecommendedCampaigns = [];
      this.getRecommendedCampaigns();
    },
    /**
     * Refresh the recommended on a successful create
     */
    recommendedCampaignCreated() {
      this.getRecommendedCampaigns();
    },
    /**
     * Copy text to clipboard
     * @param string text - The text to copy
     */
    copyToClipBoard(text) {
      navigator.clipboard.writeText(text);      
    },
    /**
     * Remove the location
     * @param {Object} location - The location to remove
     * @returns {Promise} - The promise for the location
     */
    async removeLocation(location) {
      console.log('The location to remove is: ', location);
      this.$store.dispatch('getRefreshToken');
      this.loadingCompaniesData = true;

      try {
        const res = await axios.post(`/api/location/${location.uuid}/remove`, {}, this.$store.state.header);
        this.handleRemoveLocationSuccess(res);
      } catch (err) {
        this.handleRemoveLocationError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle the success response for removing the location
     * @param {Object} res - The response object
     */
    handleRemoveLocationSuccess(res) {
      console.log('The remove location response is: ', res.data);
      this.loadingCompaniesData = false;
      this.getCompanies();
    },
    /**
     * Handle the error response for removing the location
     * @param {Object} err - The error object
     */
    handleRemoveLocationError(err) {
      console.log('The remove location error is: ', err);
      this.loadingCompaniesData = false;
    },
    /**
     * Remove the templates
     * @param {Array} templates - The templates to remove
     * @returns {Promise} - The promise for the templates
     */
    async removeTemplates(templates) {
      console.log('The templates to remove are: ', templates);
      this.$store.dispatch('getRefreshToken');
      this.loadingTemplateData = true;

      try {
        const res = await axios.post(`/api/template/admin/delete`, { template_ids: templates.map(template => template.uuid) }, this.$store.state.header);
        this.handleRemoveTemplateSuccess(res, templates[0].global_template);
      } catch (err) {
        this.handleRemoveTemplateError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle the success response for removing the template
     * @param {Object} res - The response object
     */
    handleRemoveTemplateSuccess(res, global_template=false) {
      console.log('The remove template response is: ', res.data);
      this.loadingTemplateData = false;
      this.selectedTemplates = [];
      if (global_template) {
        this.getGlobalTemplates();
      } else {
        this.getTemplates();
      }
    },
    /**
     * Handle the error response for removing the template
     * @param {Object} err - The error object
     */
    handleRemoveTemplateError(err) {
      console.log('The remove template error is: ', err);
      this.loadingTemplateData = false;
    },
    /**
     * Remove the recommended templates
     * @param {Array} templates - The recommended templates to remove
     * @returns {Promise} - The promise for the recommended templates
     */
    async removeRecommendedTemplates(templates) {
      console.log('The recommended templates to remove are: ', templates);
      this.$store.dispatch('getRefreshToken');
      this.loadingRecommendedTemplateData = true;

      try {
        const res = await axios.post(`/api/template-recommendation/admin/delete`, { template_ids: templates.map(template => template.template_recommendation_id) } , this.$store.state.header);
        this.handleRemoveRecommendedTemplateSuccess(res);
      } catch (err) {
        this.handleRemoveRecommendedTemplateError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle the success response for removing the recommended template
     * @param {Object} res - The response object
     */
    handleRemoveRecommendedTemplateSuccess(res) {
      console.log('The remove recommended template response is: ', res.data);
      this.loadingRecommendedTemplateData = false;
      this.selectedRecommendedTemplates = [];
      this.getRecommendedTemplates();
    },
    /**
     * Handle the error response for removing the template
     * @param {Object} err - The error object
     */
    handleRemoveRecommendedTemplateError(err) {
      console.log('The remove template error is: ', err);
      this.loadingRecommendedTemplateData = false;
    },
    /**
     * Remove the campaigns
     * @param {Array} campaigns - The templates to remove
     * @returns {Promise} - The promise for the templates
     */
     async removeCampaigns(campaigns) {
      console.log('The  campaigns to remove are: ', campaigns);
      this.$store.dispatch('getRefreshToken');
      this.loadingCampaignsData = true;

      try {
        const res = await axios.post(`/api/campaign/admin/delete`, { campaign_ids: campaigns.map(campaign => campaign.campaign_id) } , this.$store.state.header);
        this.handleRemoveCampaignSuccess(res);
      } catch (err) {
        this.handleRemoveCampaignError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle the success response for removing the campaign
     * @param {Object} res - The response object
     */
    handleRemoveCampaignSuccess(res) {
      console.log('The remove campaign response is: ', res.data);
      this.loadingCampaignData = false;
      this.selectedCampaigns = [];
      this.getCampaigns();
    },
    /**
     * Handle the error response for removing the template
     * @param {Object} err - The error object
     */
    handleRemoveCampaignError(err) {
      console.log('The remove campaign error is: ', err);
      this.loadingCampaignData = false;
    },
    /**
     * Remove the recommended campaigns
     * @param {Array} campaigns - The recommended templates to remove
     * @returns {Promise} - The promise for the recommended templates
     */
     async removeRecommendedCampaigns(campaigns) {
      console.log('The recommended campaigns to remove are: ', campaigns);
      this.$store.dispatch('getRefreshToken');
      this.loadingRecommendedCampaignsData = true;

      try {
        const res = await axios.post(`/api/campaign-recommendation/admin/delete`, { campaign_ids: campaigns.map(campaign => campaign.campaign_recommendation_id) } , this.$store.state.header);
        this.handleRemoveRecommendedCampaignSuccess(res);
      } catch (err) {
        this.handleRemoveRecommendedCampaignError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle the success response for removing the recommended template
     * @param {Object} res - The response object
     */
    handleRemoveRecommendedCampaignSuccess(res) {
      console.log('The remove recommended campaign response is: ', res.data);
      this.loadingRecommendedCampaignData = false;
      this.selectedRecommendedCampaigns = [];
      this.getRecommendedCampaigns();
    },
    /**
     * Handle the error response for removing the template
     * @param {Object} err - The error object
     */
    handleRemoveRecommendedCampaignError(err) {
      console.log('The remove campaign error is: ', err);
      this.loadingRecommendedCampaignData = false;
    },
    /**
     * Remove the user
     * @param {Object} user - The user to remove
     * @returns {Promise} - The promise for the user
     */
    async removeUser(user) {
      console.log('The user to remove is: ', user);
      this.$store.dispatch('getRefreshToken');
      this.loadingUsersData = true;

      try {
        const res = await axios.post(`/api/user/${user.uuid}/remove`, {}, this.$store.state.header);
        this.handleRemoveUserSuccess(res);
      } catch (err) {
        this.handleRemoveUserError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle the success response for removing the user
     * @param {Object} res - The response object
     */
    handleRemoveUserSuccess(res) {
      console.log('The remove user response is: ', res.data);
      this.loadingUsersData = false;
      this.getUsers();
    },
    /**
     * Handle the error response for removing the user
     * @param {Object} err - The error object
     */
    handleRemoveUserError(err) {
      console.log('The remove user error is: ', err);
      this.loadingUsersData = false;
    },
    /**
     * Open the add account modal
     */
    openAccountCreationModal() {
      this.$store.dispatch('getRefreshToken');
      this.displayAccountCreationModal = true;
    },
    /**
     * Open the add pharmacy modal
     * @param {Object} company 
     */
    openAddPharmacyModal(company) {
      this.$store.dispatch('getRefreshToken');
      this.currentCompany = company;
      this.displayAddPharmacyModal = true;
    },
    /**
     * Open the add company modal
     * @param {Object} company 
     */
    openAddCompanyModal() {
      this.$store.dispatch('getRefreshToken');
      this.displayAddCompanyModal = true;
    },
    /**
     * Open the add user modal
     */
    openAddUserModal(company) {
      this.$store.dispatch('getRefreshToken');
      this.currentCompany = company;
      this.displayAddUserModal = true;
    },
    /**
     * Open the edit user modal
     * @param {Object} user 
     */
    openEditUserModal(user) {
      this.$store.dispatch('getRefreshToken');
      this.currentUser = user;
      this.displayEditUserModal = true;
      console.log('The current user is: ', this.currentUser);
    },
    /**
     * Open the edit company modal
     * @param {Object} company 
     */
    openEditCompanyModal(company) {
      this.$store.dispatch('getRefreshToken');
      this.currentCompany = company;
      this.displayEditCompanyModal = true;
    },
    /**
     * Open the edit pharmacy modal
     * @param {Object} pharmacy
     */
    openEditPharmacyModal(pharmacy) {
      this.$store.dispatch('getRefreshToken');
      this.currentPharmacy = pharmacy;
      this.displayEditPharmacyModal = true;
    },
    /**
     * Open the location etl modal
     * @param {Object} pharmacy
     */
    openLocationEtlModal(pharmacy, initialRefresh) {
      this.$store.dispatch('getRefreshToken');
      this.currentPharmacy = pharmacy;
      this.displayLocationEtlModal = true;
      this.locationEtlQueueInitialRefresh = initialRefresh; // add this flag to do initial refresh when loading with uploads dashboard
    },
    /**
     * Open the edit template modal
     * @param {Array} templates
     */
    openEditTemplateModal(templates, confirmationDetails) {
      this.$store.dispatch('getRefreshToken');
      this.templateAddEditMode = 'edit';
      this.currentTemplates = templates;
      console.log("Settings page current templates", this.currentTemplates);
      this.confirmationDetails = confirmationDetails;
      this.displayAddEditTemplateModal = true;
    },
    /**
     * Open the add clinical trial modal
     */
     openAddClinicalTrialModal() {
      this.$store.dispatch('getRefreshToken');
      this.clinicalTrialAddEditMode = 'create';
      this.displayAddEditClinicalTrialModal = true;
    },
     /**
     * Open the edit clinical trial modal
     */
     openEditClinicalTrialModal(trial) {
      this.$store.dispatch('getRefreshToken');
      this.clinicalTrialAddEditMode = 'edit';
      this.selectedClinicalTrial = trial;
      this.displayAddEditClinicalTrialModal = true;
    },
    /**
     * Activate / Deactivate the templates
     * @param {Object} campaign - The templates to update
     */
      async openConfirmActivateDeactivateTemplateModal(templates, action=null) {
      this.$store.dispatch('getRefreshToken');
      if (action == null) {
        action = templates[0].active ? 'deactivate' : 'activate';
      }
      await this.getTemplateAffectedCampaigns(templates, action === 'activate' ? true : false);
      this.confirmationDetails = this.getTemplateBatchConfirmationMessage(templates, action);
      this.confirm.require({
        header: 'Confirm Template Status',
        message: this.confirmationDetails.message,
        icon: 'pi pi-exclamation-triangle',
        group: 'settings_confirmation',
        /**
         * If the user confirms the change, activate/deactivate the templates
         */
        accept: () => {
          let activeStatus = action === 'activate' ? true : false;
          this.activateDeactivateTemplates(templates, activeStatus);
        },
        reject: () => {
          console.log('The template removal was cancelled');
        }
      }) 
    },
     /**
     * Open the add recruiter / provider modal
     */
     openAddRecruiterProviderModal() {
      this.$store.dispatch('getRefreshToken');
      this.recruiterProviderAddEditMode = 'create';
      this.displayAddEditRecruiterProviderModal = true;
    },
     /**
     * Open the add recruiter / provider modal
     */
     openEditRecruiterProviderModal(recruiterProvider) {
      this.$store.dispatch('getRefreshToken');
      this.recruiterProviderAddEditMode = 'edit';
      this.selectedRecruiterProvider = recruiterProvider;
      this.displayAddEditRecruiterProviderModal = true;
    },
    /**
     * Open the edit recommended template modal
     * @param {Array} templates
     */
     openEditRecommendedTemplateModal(templates, confirmationDetails) {
      this.$store.dispatch('getRefreshToken');
      this.recommendedTemplateAddEditMode = 'edit';
      this.currentRecommendedTemplates = templates;
      this.confirmationDetails = confirmationDetails;
      this.displayAddEditTemplateRecommendedModal = true;
    },
    /**
     * Generate a message for template batch confirmation
     * @param {Array} templates - The templates to remove
     * @param {String} action - The action to perform
     * @returns {Object} - The message and whether the templates have the same name, type, and body
     */
    getTemplateBatchConfirmationMessage(templates, action) {
      let nameErrorMsg = '';
      let typeErrorMsg = '';
      let bodyErrorMsg = '';
      let subjectErrorMsg = '';
      let activeErrorMsg = '';
      let campaignsErrorMsg = '';

      if (templates.length > 0) {
        // Get the properties of the first object in the array
        const { name: referenceName, type: referenceType, body: referenceBody, subject:referenceSubject, active:referenceActive } = templates[0];
        

        // Check if all the elements have the same name, type, and body
        for (let i = 0; i < templates.length; i++) {
          const { name, type, body, subject, active } = templates[i];
          if (templates[i].email_template) {
            const { subject } = templates[i];
            if (subjectErrorMsg == '' && subject !== referenceSubject) {
              subjectErrorMsg = 'Not all selected templates have the same subject\n';
            }
          }
          if (nameErrorMsg == '' && name !== referenceName) {
            nameErrorMsg = 'Not all selected templates have the same name\n';
          }
          if (typeErrorMsg == '' && type !== referenceType) {
            typeErrorMsg = 'Not all selected templates have the same type\n';
          }
          if (bodyErrorMsg == '' && body !== referenceBody) {
            bodyErrorMsg = 'Not all selected templates have the same body\n';
          }
          if (subjectErrorMsg == '' && subject !== referenceSubject) {
            subjectErrorMsg = 'Not all selected templates have the same subject\n';
          }
          if (activeErrorMsg == '' && active !== referenceActive) {
            activeErrorMsg = 'Not all selected templates have the same active status\n';
          }
          if (campaignsErrorMsg == '' && this.campaignsToBeUpdated.length > 0) {
            campaignsErrorMsg += `The following campaigns will be ${action}d:\n`;
            this.campaignsToBeUpdated.forEach(campaign => {
              campaignsErrorMsg += ` - ${campaign}\n`;
            });
            campaignsErrorMsg += '\n';
            this.campaignsToBeUpdated = [];
          }
        }
      }
      return {
        message: `${nameErrorMsg}${typeErrorMsg}${bodyErrorMsg}${subjectErrorMsg}${activeErrorMsg}${campaignsErrorMsg}Are you sure you want to ${action} the ${templates.length} selected templates?`,
        nameSame: nameErrorMsg == '',
        typeSame: typeErrorMsg == '',
        bodySame: bodyErrorMsg == '',
        subjectSame: subjectErrorMsg == ''
      }
    },
    /**
     * Generate a message for campaign batch confirmation
     * @param {Array} campaigns - The campaigns to remove
     * @param {String} action - The action to perform
     * @returns {Object} - The message and whether the campaigns have the same name, goal, and description
     */
     getCampaignBatchConfirmationMessage(campaigns, action) {
      let nameErrorMsg = '';
      let descriptionErrorMsg = '';
      let goalErrorMsg = '';
      let noteErrorMsg = '';
      let templatesErrorMsg = '';
      let startDateErrorMsg = '';
      let endDateErrorMsg = '';
      let companyErrorMsg = '';

      if (campaigns.length > 0) {
        // Get the properties of the first object in the array
        const { name: referenceName, 
          description: referenceDescription, 
          goal: referenceGoal, goal_type: 
          referenceGoalType, measure_by: referenceMeasureBy, 
          note: referenceNote, 
          start_date: referenceStartDate, 
          end_date: referenceEndDate 
        } = campaigns[0];

        let referenceTemplates = [];
        let referenceCompany = null;
        if (campaigns[0].template_recommendations) {
          referenceTemplates = campaigns[0].template_recommendations;
        }
        else if (campaigns[0].templates) {
          referenceTemplates = campaigns[0].templates;
          referenceCompany = campaigns[0].company.uuid;
        }

        // Check if all the elements have the same name, type, and body
        for (let i = 0; i < campaigns.length; i++) {
          const { name, description, goal, goal_type, measure_by, note, start_date, end_date } = campaigns[i];
          let templates = [];
          let company = null;
          if (campaigns[i].template_recommendations) {
            templates = campaigns[i].template_recommendations;
          }
          else if (campaigns[i].templates) {
            templates = campaigns[i].templates;
            company = campaigns[i].company.uuid;
            console.log(company, referenceCompany);
          }

          if (nameErrorMsg == '' && name !== referenceName) {
            nameErrorMsg = 'Not all selected campaigns have the same name\n';
          }
          if (descriptionErrorMsg == '' && description !== referenceDescription) {
            descriptionErrorMsg = 'Not all selected campaigns have the same description\n';
          }
          if (goalErrorMsg == '' && (goal !== referenceGoal || goal_type !== referenceGoalType || measure_by !== referenceMeasureBy)) {
            goalErrorMsg = 'Not all selected campaigns have the same goal\n';
          }
          if (noteErrorMsg == '' && note !== referenceNote) {
            noteErrorMsg = 'Not all selected campaigns have the same note\n';
          }
          if (templatesErrorMsg == '') {
            let referenceTemplateIds = referenceTemplates.map(template => template.template_id);
            let templateIds = templates.map(template => template.template_id);
            if (templateIds.length !== referenceTemplateIds.length || !templateIds.every((id) => referenceTemplateIds.includes(id))) {
              templatesErrorMsg = 'Not all selected campaigns have the same templates\n';
            }
          }
          if (companyErrorMsg == '' && company !== referenceCompany) {
            companyErrorMsg = 'Not all selected campaigns are from the same company\n';
          }
          if (startDateErrorMsg == '' && start_date !== referenceStartDate) {
            startDateErrorMsg = 'Not all selected campaigns have the same start date\n';
          }
          if (endDateErrorMsg == '' && end_date !== referenceEndDate) {
            endDateErrorMsg = 'Not all selected campaigns have the same end date\n';
          }
        }
      }

      if (this.templatesToBeUpdated.length > 0) {
        templatesErrorMsg += `\nThe following templates will be ${action}d:\n`;
         this.templatesToBeUpdated.forEach(template => {
          templatesErrorMsg += ` - ${template}\n`;
        });
        templatesErrorMsg += '\n';
        this.templatesToBeUpdated = [];
      }

      return {
        message: `${nameErrorMsg}${descriptionErrorMsg}${goalErrorMsg}${noteErrorMsg}${templatesErrorMsg}${startDateErrorMsg}${endDateErrorMsg}${companyErrorMsg}Are you sure you want to ${action} the ${campaigns.length} selected campaigns?`,
        nameSame: nameErrorMsg == '',
        descriptionSame: descriptionErrorMsg == '',
        goalSame: goalErrorMsg == '',
        noteSame: noteErrorMsg == '',
        templatesSame: templatesErrorMsg == '',
        startDateSame: startDateErrorMsg == '',
        endDateSame: endDateErrorMsg == '',
        companySame: companyErrorMsg == ''
      }
    },
    /**
     * Get the clinical trial activate/deactivate confirmation message
     */
    getActivateDeactivateClinicalTrialConfirmationMessage() {
      let message = `Are you sure you want to ${this.selectedClinicalTrial.action} this clinical trial?`;
      return message;
    },
    /**
     * Open the add template modal
     */
    openAddTemplateModal() {
      this.$store.dispatch('getRefreshToken');
      this.templateAddEditMode = 'create';
      this.displayAddEditTemplateModal = true;
    },
     /**
     * Open the add recommended template modal
     */
     openAddRecommendedTemplateModal() {
      this.$store.dispatch('getRefreshToken');
      this.recommendedTemplateAddEditMode = 'create';
      this.displayAddEditTemplateRecommendedModal = true;
    },
    /**
     * Open the add Global template modal
     */
     openAddGlobalTemplateModal() {
      this.$store.dispatch('getRefreshToken');
      this.globalTemplateAddEditMode = 'create';
      this.displayAddEditGlobalTemplateModal = true;
    },
    /**
     * Open the edit Global template modal
     */
     openEditGlobalTemplateModal(globalTemplates = []) {
      this.$store.dispatch('getRefreshToken');
      this.globalTemplateAddEditMode = 'edit';
      this.currentGlobalTemplates = globalTemplates;
      this.displayAddEditGlobalTemplateModal = true;
    },
    /**
     * Update the selected pharmacy 
     */
    updatePharmacy(pharmacy) {
      Object.assign(this.currentPharmacy, pharmacy); // Assign the object to not lose reference
    },
    /**
     * Close the open modals
     */
    closeModal() {
      this.displayAccountCreationModal = false;
      this.displayAddPharmacyModal = false;
      this.displayAddCompanyModal = false;
      this.displayAddUserModal = false;
      this.displayEditUserModal = false;
      this.displayEditCompanyModal = false;
      this.displayEditPharmacyModal = false;
      this.displayLocationEtlModal = false;
      this.displayAddEditTemplateModal = false;
      this.displayAddEditTemplateRecommendedModal = false;
      this.displayAddEditCampaignModal = false;
      this.displayAddEditRecommendedCampaignModal = false;
      this.displayAddEditGlobalTemplateModal = false;
      this.displayAddEditClinicalTrialModal = false;
      this.displayAddEditRecruiterProviderModal = false;
      this.displayActivateDeactivateClinicalTrialConfirmationModal = false;
      this.currentCompany = {};
      this.currentUser = {};
      this.currentPharmacy = {};
      this.currentCampaign = {};
    },
    tagStyle(item) {
      return {
        background: item.display_colour
      }
    },
    /**
     * Format the severity for the upload status
     * @param {String} status - The upload status
     * @returns {String} - The severity for the upload status
     */
    etlStatusTagStyle(status) {
      switch (status) {
        case 'NEVER UPLOADED': 
        return {
            background: '#7B7E7E'
          }
        default: return null;
      }
    },
    /**
     * Handle the tab change
     * @param {Object} event - The tab change event
     */
    tabChange(event) {
      // If the uploads tab is selected, render the upload chart
      // Otherwise, hide the upload chart
      if (event.index === 3) {
        this.renderUploadChart = true;
      }
      else {
        this.renderUploadChart = false;
      }
    },
    /**
     * Open the confirm company deletion dialog
     * @param {Object} company - The company to remove
     */
    openConfirmCompanyDeletion(company) {
      this.$store.dispatch('getRefreshToken');
      this.confirm.require({
        header: 'Confirm Company Deletion',
        message: 'Are you sure you want to remove this company?',
        icon: 'pi pi-exclamation-triangle',
        group: 'settings_confirmation',
        /**
         * If the user confirms the deletion
         * Remove the company
         */
        accept: () => {
          this.removeCompany(company);
        },
        reject: () => {
          console.log('The company removal was cancelled');
        }
      })
    },
    /**
     * Retrieve the etl queue items on expansion of a company
     * @param {Object} event - The expand company event
     * @returns {Promise} - The promise for the etl queue items
     */
    async onCompaniesExpand(event) {
      this.$store.dispatch('getRefreshToken');
      // Get the location ids
      let ids = event.data.locations.map(location => location.uuid);
      // Set the locations' queue_status to 'LOADING'
      event.data.locations.forEach(location => {
        location.queue_status = 'LOADING';
        location.queue_items = [];
      });

      try {
        const res = await axios.post(`/api/support/view-etl-queue-items`, {id: ids}, this.$store.state.header);
        this.handleEtlQueueItemsSuccess(res, event);
      } catch (err) {
        this.handleEtlQueueItemsError(this.handleAxiosError(err, event));
      }
    },
    /**
     * Handle the success response for handling the etl queue items
     * @param {Object} res - The response object
     * @param {Object} event - The event object
     */
    handleEtlQueueItemsSuccess(res, event) {
      console.log('The handle etl queue items response is: ', res.data);
      
      res.data.forEach(location => {
        // Find the location in the event data
        const locationIndex = event.data.locations.findIndex(l => l.uuid === location.pharmacy);

        // If there is no status received
        if (!Array.isArray(location.queue) || !location.queue.length) {
          // Set the location's queue_status to 'NOT RECEIVED'
          event.data.locations[locationIndex].queue_status = 'NOT RECEIVED';
          return;
        }

        // Set the location's queue_status to the status from the response
        event.data.locations[locationIndex].queue_status = location.queue[0].etl_state;
        event.data.locations[locationIndex].queue_items = location.queue;
      })
    },
    /**
     * Handle the error response for handling the etl queue items
     * @param {Object} err - The error object
     * @param {Object} event - The event object
     */
    handleEtlQueueItemsError(err, event) {
      console.log('The etl queue items error is: ', err);

      // Set the queue_status to ERROR
      event.data.locations.forEach(location => {
        location.queue_status = 'ERROR';
      });
    },
    /**
     * Update the queue when they are refreshed in the LocationEtlModal
     * @param {Object} value
     */
    updateQueue(value) {
      // Set the location's queue_status to the status from the response
      this.currentPharmacy.queue_status = value.queue[0].etl_state;
      this.currentPharmacy.queue_items = value.queue;
    },
    /**
     * Open the confirm pharmacy deletion dialog
     * @param {Object} location - The location to remove
     */
    openConfirmPharmacyDeletion(location) {
      this.$store.dispatch('getRefreshToken');
      this.confirm.require({
        header: 'Confirm Location Deletion',
        message: 'Are you sure you want to remove this location?',
        icon: 'pi pi-exclamation-triangle',
        group: 'settings_confirmation',
        /**
         * If the user confirms the deletion
         * Remove the location
         */
        accept: () => {
          this.removeLocation(location);
        },
        reject: () => {
          console.log('The location removal was cancelled');
        }
      })
    },
    /**
     * Open the confirm template deletion dialog
     * @param {Array} templates - The templates to remove
     */
     openConfirmTemplateDeletion(templates) {
      this.$store.dispatch('getRefreshToken');
      let confirmationDetails = this.getTemplateBatchConfirmationMessage(templates, 'delete');
      this.confirm.require({
        header: 'Confirm Template Deletion',
        message: confirmationDetails.message,
        icon: 'pi pi-exclamation-triangle',
        group: 'settings_confirmation',
        /**
         * If the user confirms the deletion
         * Remove the templates
         */
        accept: () => {
          if (this.templatesTabTypeSelection === 'company') {
            this.removeTemplates(templates);
          }
          else if (this.templatesTabTypeSelection === 'global') {
            this.removeTemplates(templates);
          }
          else {
            this.removeRecommendedTemplates(templates);
          }
        },
        reject: () => {
          console.log('The template removal was cancelled');
        }
      })
    },
    /**
     * Open the confirm template edit dialog
     * @param {Array} templates - The selected templates
     */
    openConfirmTemplateEdit(templates) {
      this.$store.dispatch('getRefreshToken');
      let confirmationDetails = this.getTemplateBatchConfirmationMessage(templates, 'edit');
      this.confirm.require({
        header: 'Confirm Template Edit',
        message: confirmationDetails.message,
        icon: 'pi pi-exclamation-triangle',
        group: 'settings_confirmation',
        /**
         * If the user confirms the edit
         * Edit the templates
         */
        accept: () => {
          if (this.templatesTabTypeSelection === 'company') {
            this.openEditTemplateModal(templates, confirmationDetails);
          }
          else {
            this.openEditRecommendedTemplateModal(templates, confirmationDetails);
          }
          
        },
        reject: () => {
          console.log('The template batch details were rejected');
        }
      })
    },
     /**
     * Open the confirm campaign deletion dialog
     * @param {Array} campaigns - The campaign to remove
     */
     openConfirmCampaignDeletion(campaigns) {
      this.$store.dispatch('getRefreshToken');
      let confirmationDetails = this.getCampaignBatchConfirmationMessage(campaigns, 'delete');
      this.confirm.require({
        header: 'Confirm Campaign Deletion',
        message: confirmationDetails.message,
        icon: 'pi pi-exclamation-triangle',
        group: 'settings_confirmation',
        /**
         * If the user confirms the deletion
         * Remove the templates
         */
        accept: () => {
          if (this.campaignsTabTypeSelection === 'company') {
            this.removeCampaigns(campaigns);
          }
          else {
            this.removeRecommendedCampaigns(campaigns);
          }
        },
        reject: () => {
          console.log('The template removal was cancelled');
        }
      })
    },
    /**
     * Open the confirm Campaign edit dialog
     * @param {Array} campaigns - The selected campaigns
     */
     openConfirmCampaignEdit(campaigns) {
      this.$store.dispatch('getRefreshToken');
      let confirmationDetails = this.getCampaignBatchConfirmationMessage(campaigns, 'edit');
      this.confirm.require({
        header: 'Confirm Campaign Edit',
        message: confirmationDetails.message,
        icon: 'pi pi-exclamation-triangle',
        group: 'settings_confirmation',
        /**
         * If the user confirms the edit
         * Edit the templates
         */
        accept: () => {
          if (this.campaignsTabTypeSelection === 'company') {
            this.openEditCampaignModal(campaigns, confirmationDetails);
          }
          else {
            this.openEditRecommendedCampaignModal(campaigns, confirmationDetails);
          }
        },
        reject: () => {
          console.log('The campaign batch details were rejected');
        }
      })
    },
    /**
     * Get the upload severity
     * @param {String} status 
     */
    getUploadSeverity(status) {
      switch (status) {
        case 'RECEIVED':
        case 'OVERRIDE':
        case 'REQUESTED':
          return 'info';
        case 'NOT RECEIVED':
        return 'warning';
        case 'NEVER UPLOADED':
          return 'secondary';
        case 'PROCESSING':
          return 'help';
        case 'ERROR':
          return 'danger';
        case 'COMPLETED':
          return 'success';
        default:
          return 'secondary';
      }
    },
    /**
     * Open the confirm user deletion dialog
     * @param {Object} user - The user to remove
     */
    openConfirmUserDeletion(user) {
      this.$store.dispatch('getRefreshToken');
      this.confirm.require({
        header: 'Confirm User Deletion',
        message: 'Are you sure you want to remove this user?',
        icon: 'pi pi-exclamation-triangle',
        group: 'settings_confirmation',
        /**
         * If the user confirms the deletion
         * Remove the user
         */
        accept: () => {
          this.removeUser(user);
        },
        reject: () => {
          console.log('The user removal was cancelled');
        }
      })
    },
    /**
     * Get the recommended campaigns
     * @returns {Promise} - The promise for the recommended campaigns
     */
     async getRecommendedTemplates() {
      this.$store.dispatch('getRefreshToken');
      this.recommendedTemplateData = [];
      this.recommendedTemplatesDataError = '';
      this.paginateGetRecommendedTemplates = true;
      this.loadingRecommendedTemplateData = true;

      try {
        const res = await axios.post('/api/template-recommendation/admin/search', this.recommendedTemplatesPayload, this.$store.state.header);
        this.handleRecommendedTemplateSuccess(res);
      } catch (err) {
        this.handleRecommendedTemplateError(this.handleAxiosError(err));
      }
     },
     /**
      * Handle the success response for the recommended campaigns
      * @param {Object} res - The response object
      */
      handleRecommendedTemplateSuccess(res) {
        console.log('The recommended templates response is: ', res.data);
        this.recommendedTemplateData = res.data
        this.recommendedTemplateData.templates.forEach(template => {
         if (template.email_template && template.sms_template) {
           template.type = 'both';
          }
          else if (template.email_template) {
            template.type = 'email';
          }
          else if (template.sms_template) {
            template.type = 'sms';
          }
        });
        this.loadingRecommendedTemplateData = false;
      },
      /**
       * Handle the error response for the recommended campaigns
       * @param {Object} err - The error object
       */
       handleRecommendedTemplateError(err) {
        console.log('The recommended templates error is: ', err);
        this.loadingRecommendedTemplateData = false;
        this.recommendedTemplatesDataError = err;
      },
    /**
     * Get the recommended campaigns
     * @returns {Promise} - The promise for the recommended campaigns
     */
     async getCampaigns() {
      this.$store.dispatch('getRefreshToken');
      this.campaignsData = [];
      this.campaignsDataError = '';
      this.loadingCampaignsData = true;

      try {
        const res = await axios.post('/api/campaign/admin/search', this.campaignSearchPayload, this.$store.state.header);
        this.handleCampaignsSuccess(res);
      } catch (err) {
        this.handleCampaignsError(this.handleAxiosError(err));
      }
     },
     /**
      * Handle the success response for the campaigns
      * @param {Object} res - The response object
      */
      handleCampaignsSuccess(res) {
        console.log('The campaigns response is: ', res.data);
        this.campaignsData = res.data;
        this.loadingCampaignsData = false;
      },
      /**
       * Handle the error response for the campaigns
       * @param {Object} err - The error object
       */
      handleCampaignsError(err) {
        console.log('The campaigns error is: ', err);
        this.loadingCampaignsData = false;
        this.campaignsDataError = err;
      },
    /**
     * Get the templates names that will be updated when the campaign status is changed
     * @returns {Promise} - The promise for the templates
     */  
    async getCampaignAffectedTemplates (campaigns, active) {
      this.$store.dispatch('getRefreshToken');
      this.templatesToBeUpdated = [];
      this.loadingCampaignAffectedTemplates = true;

      let payload = {
        campaign_ids: campaigns.map(campaign => campaign.campaign_id),
        active: active
      }

      try {
        const res = await axios.post('/api/campaign/admin/affected-templates', payload, this.$store.state.header);
        this.handleGetCampaignAffectedTemplatesSuccess(res);
      } catch (err) {
        this.handleGetCampaignAffectedTemplatesError(this.handleAxiosError(err));
      }
    },
    /**
    * Handle the success response for the templates affected by the campaign status change
    * @param {Object} res - The response object
    */
    handleGetCampaignAffectedTemplatesSuccess(res) {
      console.log('The affected templates response is: ', res.data);
      this.loadingCampaignAffectedTemplates = false;
      this.templatesToBeUpdated = res.data.template_names;
    },
    /**
     * Handle the error response for the campaigns
     * @param {Object} err - The error object
     */
     handleGetCampaignAffectedTemplatesError(err) {
      console.log('The affected templates error is: ', err);
      this.loadingCampaignAffectedTemplates = false;
    },
    /**
     * Get the recommended campaigns
     * @returns {Promise} - The promise for the recommended campaigns
     */
     async getRecommendedCampaigns() {
      this.$store.dispatch('getRefreshToken');
      this.recommendedCampaignsData = [];
      this.recommendedCampaignsDataError = '';
      this.loadingRecommendedCampaignsData = true;

      try {
        const res = await axios.post('/api/campaign-recommendation/admin/search', this.recommendedCampaignsPayload, this.$store.state.header);
        this.handleRecommendedCampaignsSuccess(res);
      } catch (err) {
        this.handleRecommendedCampaignsError(this.handleAxiosError(err));
      }
     },
     /**
      * Handle the success response for the recommended campaigns
      * @param {Object} res - The response object
      */
      handleRecommendedCampaignsSuccess(res) {
        console.log('The recommended campaigns response is: ', res.data);
        this.recommendedCampaignsData = res.data;
        this.loadingRecommendedCampaignsData = false;
      },
      /**
       * Handle the error response for the recommended campaigns
       * @param {Object} err - The error object
       */
      handleRecommendedCampaignsError(err) {
        console.log('The recommended campaigns error is: ', err);
        this.loadingRecommendedCampaignsData = false;
        this.recommendedCampaignsDataError = err;
      },
      /**
       * Activate / Deactivate the campaigns
       * @param {Object} campaign - The campaigns to update
       */
      activateDeactivateCampaign(campaigns, active=null) {
        this.$store.dispatch('getRefreshToken');
        let campaignIds = campaigns.map(campaign => campaign.campaign_id);
        let payload = {
          campaign_ids: campaignIds,
          active: active
        }
        this.loadingCampaignData = true;
        axios.post(`/api/campaign/admin/edit-active-status`, payload, this.$store.state.header)
        .then(res => {
          this.handleActivateDeactivateCampaignSuccess(res);
        })
        .catch(err => {
          this.handleActivateDeactivateCampaignError(this.handleAxiosError(err));
        })
      },
      /**
       * Handle the success response for activating/deactivating the campaign
       * @param {Object} res - The response object
       */
      handleActivateDeactivateCampaignSuccess(res) {
        console.log('The activate/deactivate campaign response is: ', res.data);
        this.loadingCampaignData = false;
        this.getCampaigns();
      },
      /**
       * Handle the error response for activating/deactivating the campaign
       * @param {Object} err - The error object
       */
      handleActivateDeactivateCampaignError(err) {
        console.log('The activate/deactivate campaign error is: ', err);
        this.campaignsDataError = err;
        this.loadingCampaignData = false;
      },
      /**
       * Open the add campaign modal 
       */
       openAddCampaignModal() {
        this.$store.dispatch('getRefreshToken');
        this.campaignAddEditMode = 'create';
        this.displayAddEditCampaignModal = true;
      },
      /**
       * Open the edit campaign modal
       * @param {Object} campaign - The campaign to edit
       */
       openEditCampaignModal(campaigns, confirmationDetails) {
        this.$store.dispatch('getRefreshToken');
        this.campaignAddEditMode = 'edit';
        this.currentCampaigns = campaigns;
        this.confirmationDetails = confirmationDetails;
        this.displayAddEditCampaignModal = true;
      },
      /**
       * Open the edit campaign modal
       * @param {Object} campaign - The campaign to edit
       */
       async openConfirmActivateDeactivateCampaignModal(campaigns, action=null) {
        this.$store.dispatch('getRefreshToken');
        if (action == null) {
          action = campaigns[0].active ? 'deactivate' : 'activate';
        }
        await this.getCampaignAffectedTemplates(campaigns, action === 'activate' ? true : false);
        this.confirmationDetails = this.getCampaignBatchConfirmationMessage(campaigns, action);
        this.confirm.require({
          header: 'Confirm Campaign Status',
          message: this.confirmationDetails.message,
          icon: 'pi pi-exclamation-triangle',
          group: 'settings_confirmation',
          /**
           * If the user confirms the deletion
           * Remove the campaign
           */
          accept: () => {
            let activeStatus = action === 'activate' ? true : false;
            this.activateDeactivateCampaign(campaigns, activeStatus);
          },
          reject: () => {
            console.log('The campaign removal was cancelled');
          }
        }) 
      },
      /**
       * Open the add campaign modal 
       */
       openAddRecommendedCampaignModal() {
        this.$store.dispatch('getRefreshToken');
        this.recommendedCampaignAddEditMode = 'create';
        this.displayAddEditRecommendedCampaignModal = true;
      },
      /**
       * Open the edit recommended campaign modal
       * @param {Object} campaign - The campaign to edit
       */
      openEditRecommendedCampaignModal(campaigns, confirmationDetails) {
        this.$store.dispatch('getRefreshToken');
        this.recommendedCampaignAddEditMode = 'edit';
        this.currentRecommendedCampaigns = campaigns;
        this.confirmationDetails = confirmationDetails;
        this.displayAddEditRecommendedCampaignModal = true;
      },
     /**
     * Get the details of ETL Queue for Strong Pro Pharmacies that has faulty ETL uploads in the given time period
     * @returns {Promise} - The promise for the Pharmacies
     */
     async getFaultyEtlItems() {
      this.$store.dispatch('getRefreshToken');
      this.faultyEtlPharmacyData = [];
      this.faultyEtlPharmacyDataError = '';
      this.loadingFaultyEtlPharmacyData = true;
      
      try {
        const res = await axios.post('/api/support/get-faulty-etl-pharmacies', this.proPharmacyListPayload, this.$store.state.header);
        this.handleFaultyEtlItemsSuccess(res);
      } catch (err) {
        this.handleFaultyEtlItemsError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle the success response for the Pharmacies with Strong Pro Module
     * @param {Object} res - The response object
     */
     handleFaultyEtlItemsSuccess(res) {
      console.log('The Faulty ETL Queue response is: ', res.data);
      var etlResponseData = res.data;
      
      // ETL Stats Data
      this.etlStatsData = etlResponseData.etl_stats;

      // create PIE chart data and options
      this.setUploadsPieChartData();

      this.etlStatsData.noUploadPharmacies = etlResponseData.etl_items.length - this.etlStatsData['NEVER UPLOADED'];

      etlResponseData.etl_items.forEach(pharmacy=> {
        var etlProcessedData = pharmacy
        // If there is a previous successful upload time, create a date_time object
        if (etlProcessedData.last_successful_upload_on != null) {
          etlProcessedData.last_successful_upload_on = new Date(etlProcessedData.last_successful_upload_on);        
        }
        // If there is a last upload time, create a date_time object
        if ( etlProcessedData.upload_time != null) {
          etlProcessedData.upload_time = new Date(etlProcessedData.upload_time);
          etlProcessedData.uploadTimestamp = etlProcessedData.upload_time.getTime();
        }
        // Set the sort order of the status and update the status counts for the charts
        if (etlProcessedData.status == "ERROR") {
          etlProcessedData.sortStatusField = 4; // Set the sort order of ERROR - most important
        }
        else if (etlProcessedData.status === "NEVER UPLOADED") {
          etlProcessedData.sortStatusField = 0; // Set the sort order of NEVER UPLOADED - least important
        }
        else if (etlProcessedData.status === "NOT RECEIVED") {
          etlProcessedData.sortStatusField = 3; // Set the sort order of NOT RECEIVED - 2nd most important
        }
        else if (etlProcessedData.status == "REQUESTED") {
          etlProcessedData.sortStatusField = 1 // Set the sort order of REQUESTED
        }
        else if (etlProcessedData.status == "RECEIVED") {
          etlProcessedData.sortStatusField = 2; // Set the sort order of RECEIVED
        }
        // Check the status is in the options list for the filter drop down
        if (!this.uploadStatuses.includes(etlProcessedData.status)) {
          this.uploadStatuses.push(etlProcessedData.status);
        }

        // add the processed ETL data for the faulty ETL queue
        this.faultyEtlPharmacyData.push(etlProcessedData);
      });

      this.faultyEtlPharmacyData.forEach(pharmacy => {
        this.expandedProPharmaciesRow.push({
          [pharmacy.pharmacy.uuid]: false
        })
      });
      
      
      this.loadingFaultyEtlPharmacyData = false;
    },
    /**
     * Handle the error response for the companies
     * @param {Object} err - The error object
     */
     handleFaultyEtlItemsError(err) {
      console.log('The Faulty ETL Queue error is: ', err);
      this.loadingFaultyEtlPharmacyData = false;
      this.faultyEtlPharmacyDataError = err;
    },
     /**
     * Handles the pie chart data and options for the ETL Queue request with Pharmacies with faulty uploads
     */
    setUploadsPieChartData() {
      let labels = ['COMPLETED', 'NOT RECEIVED', 'ERROR', 'REQUESTED', 'RECEIVED', 'OVERRIDE', 'NEVER UPLOADED', 'PROCESSING']; // labels for the pie chart
      //let data = Object.values(this.etlStatsData);
      let data = [];
      for (let i = 0; i < labels.length; i++) {
        data.push(this.etlStatsData[labels[i]]);
      }
      this.uploadsPieChartData = {
        labels: labels,
        datasets: [
          {
            data: data, // data for the pie chart
            hoverBackgroundColor: ['#83C48D', '#FED182', '#F7B9B0', '#86C3EC', '#94D6D0', '#F2B5D8', '#BDC4C4', '#C399D8'], // hover background color for the pie chart
            backgroundColor: ['#00AD50', '#EF9600', '#E04F39', '#2E97DE', '#00B2A2', '#E56DB1', '#7B7E7E', '#9F5CC0'], // background color for the pie chart - green, orange, red, blue, teal, pink, grey ,purple
          }
        ]
      }

      // Set the options for the pie chart
      this.uploadsPieChartOptions =  {
        plugins: {
            legend: {
                labels: {
                    render: (args) => {
                      return args.label + args.values // render the label and the value
                    }
                }
            },
            // add the data labels for the pie chart
            datalabels: {
              formatter: (value, ctx) => {
                const datapoints = ctx.chart.data.datasets[0].data
                const total = datapoints.reduce((total, datapoint) => total + datapoint, 0)
                const percentage = value / total * 100
                return percentage.toFixed(2) + "%";
              },
              color: '#fff',
              responsive: false,
           },
        }
      };
    },
    /**
     * Handle the update filters for support uploads dashboard tab to show pharmacies with faulty uploads
     * @param String periodType - The time period type for the filter
     * @param String periodValue - The time period value for the filter
     */
     updateFaultyUploadFilter(periodType, periodValue) {
      this.selectedPeriodTypeNoUploads = periodType; // update the selected period type
      this.selectedInputNoUploads = parseInt(periodValue); // update the selected period value
      this.getFaultyEtlItems(); // function to fetch pharmcies with faulty ETL items for uploads dashboard
    },
    /**
     * Get the clinical trials
     * @returns {Promise} - The promise for the clinical trials
     */
     async getClinicalTrials() {
      this.$store.dispatch('getRefreshToken');
      this.clinicalTrialsData = [];
      this.clinicalTrialsDataError = '';
      this.loadingClinicalTrialsData = true;

      try {
        const res = await axios.post('/api/clinical-trial/admin/search', { "page": this.$store.state.clinicalTrialsPage || 1 , "name": this.clinical_trial_search }, this.$store.state.header);
        this.handleClinicalTrialsSuccess(res);
      } catch (err) {
        this.handleClinicalTrialsError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle the success response for the clinical trials
     * @param {Object} res - The response object
     */
    handleClinicalTrialsSuccess(res) {
      console.log('The clinical trials response is: ', res.data);
      this.clinicalTrialsData = res.data;
      console.log('The clinical trials rows are: ', this.clinicalTrialsData);
      this.clinicalTrialsData.clinical_trials.forEach(clinicalTrial => {
        clinicalTrial.locations = [];
        clinicalTrial.loadingExandedRow = false;
        clinicalTrial.numLocations = 0;
        clinicalTrial.pageSize = 0;
        this.expandedClinicalTrialsRows.push({
          [clinicalTrial.trial_id]: false
        })
      })
      this.loadingClinicalTrialsData = false;
    },
    /**
     * Handle the error response for the clinical trials
     * @param {Object} err - The error object
     */
    handleClinicalTrialsError(err) {
      console.log('The clinical trials error is: ', err);
      this.loadingClinicalTrialsData = false;
      this.clinicalTrialsDataError = err;
    },
    /**
     * Retrieve the counts of location statuses on expansion of a clinical trials
     * @param {Object} event - The expand trials event
     * @returns {Promise} - The promise for the clinical trials location info
     */
     async onClinicalTrialsExpand(event) {
      this.$store.dispatch('getRefreshToken');

      let trial_id = event.data.trial_id;
      event.data.loadingExandedRow = true;
      try {
        let payload = {
          page: this.$store.state.clinicalTrialsLocationPage || 1,
        }
        const res = await axios.post(`/api/clinical-trial/admin/${trial_id}/location-info`, payload, this.$store.state.header);
        this.handleClinicalTrialLocationInfoSuccess(res, event);
      } catch (err) {
        this.handleClinicalTrialLocationInfoError(this.handleAxiosError(err, event));
      }
    },
    /**
     * Handle the success response for handling the clinical trial location
     * @param {Object} res - The response object
     * @param {Object} event - The event object
     */
    handleClinicalTrialLocationInfoSuccess(res, event) {
      console.log('The handle clinical trial location info response is: ', res.data);
      console.log('The handle clinical trial location info event is: ', event);

      event.data.locations = res.data.locations;
      event.data.loadingExandedRow = false;
      event.data.numLocations = res.data.page_count * res.data.per_page;
      event.data.pageSize = res.data.per_page;
    },
    /**
     * Handle the error response for handling the clinical trial location info
     * @param {Object} err - The error object
     * @param {Object} event - The event object
     */
     handleClinicalTrialLocationInfoError(err, event) {
      console.log('The clinical trial location info error is: ', err);
      event.data.loadingExandedRow = false;

    },
    /**
     * Handle the clinical trial search
     */
    onLocationPageChange(clinicalTrialData, event) {
      this.$store.dispatch('getRefreshToken');
      clinicalTrialData = {data: clinicalTrialData}
      this.$store.state.clinicalTrialsLocationPage = event.page + 1;
      this.onClinicalTrialsExpand(clinicalTrialData);
    },
    /**
     * Format the eligibility criteria for the clinical trials
     * @returns {array} - The list of formatted eligibility criteria
     */
     eligibilityCriteriaList(eligibility_criteria) {
      let formattedList= eligibility_criteria.split('\n');
      for (let i = 0; i < formattedList.length; i++) {
        formattedList[i] = formattedList[i].replace("- ", "").trim();
      }
      return formattedList;
    },
    /**
     * Open the edit active status of clinical trial modal
     * @param {Object} clinicalTrial - The clinical trial to edit
     */
     async openConfirmActivateDeactivateClinicalTrialModal(clinicalTrial) {
      this.$store.dispatch('getRefreshToken');
      this.selectedClinicalTrial = clinicalTrial;
      this.selectedClinicalTrial.action = this.clinicalTrialAction;
      this.displayActivateDeactivateClinicalTrialConfirmationModal = true;
    },
    /**
     * Activate / Deactivate the clinical trial
     * @param {Object} clinicalTrial - The clinical trial to update
     */
    activateDeactivateClinicalTrial(notifyPharmacies = true, cancelMessages=true) {
      this.$store.dispatch('getRefreshToken');
      this.loadingClinicalTrialsData = true;
      this.selectedClinicalTrial.notifyPharmacies = notifyPharmacies;
      this.selectedClinicalTrial.cancelMessages = cancelMessages;
      axios.post(`/api/clinical-trial/admin/${this.selectedClinicalTrial.trial_id}/edit-active-status`, this.activateDeactivateClinicalTrialPayload, this.$store.state.header)
      .then(res => {
        this.handleActivateDeactivateClinicalTrialSuccess(res);
      })
      .catch(err => {
        this.handleActivateDeactivateClinicalTrialError(this.handleAxiosError(err));
      })
    },
    /**
     * Handle the success response for activating/deactivating the clinical trial
     * @param {Object} res - The response object
     */
    handleActivateDeactivateClinicalTrialSuccess(res) {
      console.log('The activate/deactivate clinical trial response is: ', res.data);
      this.loadingClinicalTrialData = false;
      this.getClinicalTrials();
    },
    /**
     * Handle the error response for activating/deactivating the clinical trial
     * @param {Object} err - The error object
     */
    handleActivateDeactivateClinicalTrialError(err) {
      console.log('The activate/deactivate clinical trial error is: ', err);
      this.clinicalTrialsDataError = err;
      this.loadingClinicalTrialData = false;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/css/main.scss";

.grid-container {
  display: grid;
  grid-template-columns: 3fr 2fr;
  column-gap: 40px;
  height: 100%;
}

.left-col {
  display: grid;
  grid-column-start: 1;
  grid-column-end: 3;
  // Required to prevent grid overflow
  overflow: hidden;
}

.right-col {
  grid-column-start: 3;
  grid-column-end: 4;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.filters-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 45px;
  margin-bottom: 1em;
  gap: 20px;
}

.category-combobox {
  flex: 1 1 300px;
}

.upload-stats {
  display: flex;
  justify-content: space-between;
  flex-basis: 100%;
  border-radius: 12px;
  height: 80px;
  margin-bottom: 1em;
}

.upload_card {
  display: flex;
  flex-direction: column;
  flex: 1 1 0px;
  justify-content: center;
}

.stats-value {
  margin: 5px;
}

.stats-top-row {
  display: flex;
  width: 100%;
  gap: 5px;
  align-items: center;
  padding: 0 5px;
  flex: 1;
}

.stats-heading {
  font-size: 14px;
  font-weight: 600;
  opacity: 0.7;
  margin: 0;
  flex: 1;
}

.stats-percentage {
  font-size: 12px;
  font-weight: 600;
  margin: 0;
}

.upload_card + .upload_card {
  border-left: solid 2px $bordergrey;
}

.export {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  height: 40px;
  gap: 25px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background-color: $grey;
  border: 1px solid $bordergrey;
  border-bottom: none;
}

.positive-rate {
  color: green;
}

.negative-rate {
  color: red;
}

.neutral-rate {
  color: grey;
}

.key-container {
  display: flex;
  gap: 1.5em;
}

.key-icon {
  width: 14px;
  height: 14px;
  border-radius: 2px;
  display: inline-block;
}

.key-heading {
  font-size: 11pt;
  font-weight: 600;
  color: $deepgrey;
}

.key-text {
  font-size: 11pt;
  font-weight: 500;
  color: $deepgrey;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.p-progress-spinner {
  margin: 0 0 0 auto;
}

.table-main-header {
  font-size: 13pt;
  font-weight: 600 !important;
  color: black !important;
}

.table-sub-header {
  font-size: 12pt;
  font-weight: 500 !important;
  color: $deepgrey !important;
}

.totals-row {
  background-color: $phoneorders !important;
  color: white !important;
  font-size: 13pt;
}

.average-row {
  background-color: $babyblue !important;
  color: $phoneorders !important;
  font-size: 13pt;
}

.adherence-span {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.page-header {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 60px;
  height: 60px;
  margin: 10px 0 10px 0;

  h1 {
    margin: 0;
  }
}

.nav-functions {
  align-self: flex-start;
  z-index: 3;
}

.table-footer {
  display: flex;
  align-items: center;
}

.flex-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.flex-right {
  margin-left: auto;
}

.p-datatable th[class*="align-"] .p-column-header-content {
  display: inline-flex ;
}

.no-borders * {
  border : 0px !important;
}

.align-center {
  text-align: center !important;
}

:deep(.p-tabview) {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  overflow: hidden;
}

:deep(.p-tabview-panels) {
  padding: 0 !important;
}

:deep(.p-tabview-nav-link) {
  justify-content: center;
  background: $babyblue !important;
  height: 60px;
}

:deep(.p-tabview-header) {
  height: 58px;
}

.p-tabview {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  overflow: hidden;
}

.p-tabview-panels {
  padding: 0 !important;
}

.p-tabview-nav-link {
  justify-content: center;
  background: $babyblue !important;
  height: 60px;
}

.p-tabview-header {
  flex: 1;
  height: 58px;
}

.opp-calc {
  background-color: $babyblue;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding-left: 2em;
  padding-right: 2em;
  padding-bottom: 1em;
  gap: 8px;
}

.calc-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.opp-calc h3 {
  font-size: 14pt;
  color: $phoneorders;
}

.opp-calc h4 {
  font-size: 12pt;
  color: $phoneorders;
  margin: 0.5em 0 0.67em 0;
}

.adjustment {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.adjustment .p-togglebutton {
  flex: 1 1 100px;
}

.input-span {
  flex: 3 1 100px;
}

.input-span .p-inputtext {
  width: 100%;
}

.p-inputtext,
.p-selectbutton,
.p-password-input,
.p-togglebutton {
  border-radius: 5px;
  border: 1px solid $phoneorders;
  overflow: hidden;
}

.p-selectbutton .p-button {
  border: none !important;
}

.flex-expand {
  flex: 1;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.output-calc {
  background-color: white;
  border-radius: 6px;
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 0.5em;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2em;
}

.opportunity-left-col {
  grid-column: 1 / 2;
}

.opportunity-right-col {
  grid-column: 2 / 3;
}

.opportunity-heading {
  padding: 0.5em 1em 0 0.5em;;
}

.opportunity-data {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 3.1875rem;
}

.data-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.arrow {
  justify-content: flex-start;
}

.potential-container {
  background-color: $phoneorders;
  border-radius: 6px;
  padding: 0.5em 1em 1em 0.5em;
}

.current-container {
  padding: 0.5em 1em 1em 0.5em;
}

.output-current-data {
  font-size: 20pt;
  color: $phoneorders;
  margin: 0;
}

.output-current-label {
  font-size: 11pt;
  color: $phoneorders;
  opacity: 0.75;
  margin: 0;
}

.output-potential-data {
  font-size: 20pt;
  color: white;
  margin: 0;
}

.output-potential-label {
  font-size: 11pt;
  color: white;
  opacity: 0.75;
  margin: 0;
}

.output-skeleton {
  height: 50px !important;
}

.stats-skeleton {
  height: 60px !important;
}

.header-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  padding: 0.5em 0 0.5em 0;
}

.upload-header-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.5em;
  padding: 0.5em 0 0.5em 0;
}
.table-selection {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2em;
  p {
    font-size: 16px;
    font-weight: 600;
    color: $strongblue;
    margin: 0;
  }
}

.checkbox-input {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.5em;
}

.item-data {
  margin-top: 1em;
}

.item-button {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
}

.detail-item-header {
  font-weight: 600;
  font-size: 18px;
}

.detail-item-value {
  font-size: 16px;
  font-weight: 500;
  color: $deepgrey;
}

</style>