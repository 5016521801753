<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div
        class="modal-container"
        style="width: 30%;"
      >
        <!-- HEADER -->
        <div class="modal-header">
          <slot name="header">
            <h3 
              style="padding-left: 1em;"
              class="form-header black-text"
            >
              {{ title }}
            </h3>
            <button
              class="red-close-button"
              @click="closeModal"
            >
              <i
                class="pi pi-times"
              ></i>
            </button>
          </slot>
        </div>
        <!-- BODY -->
        <div class="modal-body">
          <slot name="body">
            <div class="form-container">
              <div
                style="margin-bottom: 0.5em;"
                class="form-row"
              >
                {{ message }}
              </div>
              <div
                style="margin-bottom: 0.5em;"
                class="form-row"
              >
                <div class="flex-input flex-item">
                  <label for="notifyPharmacies">Notfiy affected pharmacies of the change</label>
                  <Checkbox
                    id="notifyPharmacies"
                    v-model="notifyPharmacies"
                    style="margin-left: 1em;"
                    :binary="true"
                  />
                </div>
              </div>
              <div
                v-if="!active"
                style="margin-bottom: 0.5em;"
                class="form-row"
              >
                <div class="flex-input flex-item">
                  <label for="cancelMessages">Cancel any scheduled messages for this trial</label>
                  <Checkbox
                    id="cancelMessages"
                    v-model="cancelMessages"
                    style="margin-left: 1em;"
                    :binary="true"
                  />
                </div>
              </div>
            </div>
          </slot>
        </div>
        <div
          class="modal-footer"
          style="display: flex; justify-content: center; padding: 5px 20px;"
        >
          <slot
            name="footer"
            style="display: flex;"
          >
            <div
              class="grey-button flex-item"
              @click="closeModal"
            >
              Cancel
            </div>
            <div
              class="black-button flex-item"
              @click="activateDeactivateClinicalTrial()"
            >
              Confirm
            </div>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ActivateDeactivateClinicalTrialConfirmationModal',
  props: {
    /**
     * The title of the modal
     * @type String
     */
    title: {
      type: String,
      default() {
        return '';
      }
    },
    /**
     * The body of the modal
     */
    message: {
      type: String,
      default() {
        return '';
      }
    },
    active: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  /**
   * Emits events to parent component
   */
  emits: [
    'closeModal',
    'confirm'
  ],
  data() {
    /**
     * The local data for the component
     */
    return {
      notifyPharmacies: true,
      cancelMessages: this.active ? false : true
    }
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    /**
     * Activate or deactivate the clinical trial
     */
    activateDeactivateClinicalTrial() {
      this.$emit('confirm', this.notifyPharmacies, this.cancelMessages);
      this.closeModal();
    },
    /**
     * Emit the close modal event
     */
    closeModal() {
      this.$emit('closeModal');
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/main.scss";

.modal-container {
  display: flex;
  flex-direction: column;
  min-height: 20vh;
  min-width: 50%;
}

.form-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 1.5em 1.5em 1.5em;
  text-align: left;
}

.form-row {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-item {
  flex: 1;
}

.modal-body {
  display: flex;
  flex-direction: column;
  padding: 20px;
  flex: 1;
}

.form-header {
  font-size: 1.5em;
  font-weight: bold;
}

.right-margin {
  margin-right: 1em;
}

.bottom-margin {
  margin-bottom: 1em;
}

.black-text {
  color: $black;
}


</style>
