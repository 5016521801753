<template>
  <div class="edit-form-container">
    <div
      class="flex-row"
    >
      <div class="flex-item">
        <div class="d-flex flex-col ga-2">
          <label class="fs-14 font-weight-500">Campaign Name</label>
          <InputText 
            v-model="campaign_data.name" 
            @update:model-value="onUpdateCampaign"
          />
        </div>
      </div>
      <div class="col-2 d-flex flex-wrap align-end">
        <Button
          label="Refine Cohort"
          severity="secondary"
          style="width: 100%;"
          class="button-dark-grey"
          @click="openCampaignRefineCohortModal"
        />
      </div>
    </div>
    <div
      class="flex-row"
    >
      <div class="flex-item">
        <label class="fs-14 font-weight-500">
          Description (Optional)
        </label>
        <InputText 
          v-model="campaign_data.description" 
          @update:model-value="onUpdateCampaign"
        />
      </div>
    </div>
    <div
      class="flex-row"
    >
      <div class="d-flex flex-col flex-item">
        <label class="fs-14 font-weight-500">
          Participating Pharmacies
        </label>
        <MultiSelect
          v-model="campaign_data.pharmacies"
          :options="companyPharmacies"
          option-label="pharmacy_name"
          option-value="pharmacy_id"
          class="mb-3"
          @update:model-value="onUpdateCampaign"
        />
      </div>
      <div class="d-flex flex-col">
        <label class="fs-14 font-weight-500">End Date</label>
        <Calendar
          v-model="campaign_data.end_date"
          :show-button-bar="true"
          icon-display="input"
          placeholder="DD/MM/YYYY"
          date-format="dd/mm/yy"
          @update:model-value="onUpdateCampaign"
        />
      </div>
    </div>
    <div class="font-weight-700 text-black">
      Campaign Goal
    </div>
    <p 
      class="fs-16 font-weight-500"
      style="margin: 0rem;"
    >
      Select a goal type, choose a method to measure success and set your target value to track performance.
    </p>
    <div class="row">
      <div class="col col-4">
        <div class="d-flex flex-col ga-2">
          <label class="fs-14 font-weight-500">Goal Type</label>
          <Dropdown
            v-model="campaign_data.goal_type"
            :options="goal_type_options"
            option-label="label"
            option-value="value"
            class="mb-3"
            @update:model-value="onUpdateCampaign"
          />
        </div>
      </div>
      <div class="col col-4">
        <div class="d-flex flex-col ga-2">
          <label class="fs-14 font-weight-500">Measure By</label>
          <Dropdown
            v-model="campaign_data.measure_by"
            :options="measure_by_options"
            option-label="label"
            option-value="value"
            :option-disabled="option => (campaign_data.goal_type === 'loyalty' || campaign_data.goal_type === 'adherence') && (option.value==='value') ? true : false"
            class="mb-3"
            @update:model-value="onUpdateCampaign"
          />
        </div>
      </div>
      <div class="col col-4">
        <div class="d-flex flex-col ga-2">
          <label class="fs-14 font-weight-500">Set Goal</label>
          <InputNumber 
            v-model="campaign_data.goal" 
            @update:model-value="onUpdateCampaign"
          />
        </div>
      </div>
    </div>
    <CampaignEditTemplate 
      v-model="campaign_data.templates"
      :pre-selected-templates="modalSource === 'recommended' ? campaign.template_recommendations : campaignInfo.templates"
      :modal-source="modalSource"
      @update:model-value="onUpdateCampaign" 
    />
    <CampaignRefineCohortModal
      v-if="displayRefineCohortModal"
      :campaign="campaign_data"
      :prefix-filters="prefixFilters"
      @filters="setFilters"
      @close-modal="displayRefineCohortModal = false"
    />
  </div>
</template>

<script>
import CampaignEditTemplate from '@/components/campaign/form/campaign-edit-template.vue';
import CampaignRefineCohortModal from '@/components/campaign/campaign-refine-cohort-modal.vue';

export default {
  components: {
    CampaignEditTemplate,
    CampaignRefineCohortModal,
  },
  props: {
    campaign: {
      type: Object,
      required: true,
    },
    campaignInfo: {
      type: Object,
      default() {
        return {}
      }
    },
    companyPharmacies: {
      type: Array,
      default: () => []
    },
    modalSource: {
      type: String,
      default: 'campaign'
    }
  },
  emits: [
    'closeModal',
    'campaignUpdated',
    'campaignCreated',
  ],
  data() {
    return {
      campaign_data: {
        name: null,
        patient_cohort: null,
        description: null,
        end_date: null,
        goal_type: null,
        measure_by: null,
        goal: null,
        templates: [],
        pharmacies: [],
      },
      goal_type_options: [
        { label: 'Revenue', value: 'revenue' },
        { label: 'Adherence', value: 'adherence' },
        { label: 'Loyalty', value: 'loyalty' },
        { label: 'Scripts', value: 'scripts' },
      ],
      measure_by_options: [
        { label: 'Target', value: 'target' },
        { label: 'Percentage', value: 'percentage' },
        { label: 'Value', value: 'value' },
      ],
      displayRefineCohortModal: false,
      loadingSaveCampaign: false,
      errorMessage: '',
    }
  },
  watch: {
    campaign: {
      handler() {
        this.campaign_data = {
          ...this.campaign_data,
          ...this.campaign
        };
      },
      deep: true
    }
  },
  mounted() {
    this.campaign_data = {
      ...this.campaign_data,
      ...this.campaign
    };

    this.campaign_data.patient_cohort = this.campaignInfo.filters;
    this.campaign_data.description = this.campaignInfo.note;
    this.campaign_data.goal_type = this.campaignInfo.goal_type;
    this.campaign_data.measure_by = this.campaignInfo.measure_by;
    this.campaign_data.goal = this.campaignInfo.goal;
    this.campaign_data.end_date = this.campaignInfo.end_date ? this.campaignInfo.end_date : null;
    if (this.modalSource === 'recommended') {
      this.campaign_data.templates = this.campaignInfo.template_recommendations.map(template => template.template_id);
    }
    else {
      this.campaign_data.templates = this.campaignInfo.templates.map(template => template.template_id);
    }
    this.campaign_data.pharmacies = this.campaignInfo.locations.map(location => location.location_id);

    if (this.campaignInfo.filters) {
    this.prefixFilters = structuredClone(this.campaignInfo.filters);
    }
    else {
      this.prefixFilters = {
        adherence: [],
        loyalty: [],
        patient_type: [],
        age: '',
        patient_sex: [],
        polypharmacy: '',
        drug_type: [],
        distance_from_store: '',
        double_dispensing: [],
        molecules: []
      };
    }

    this.onUpdateCampaign();
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
    /**
     * On updating the fields emit the data to the parent component
     */
    onUpdateCampaign() {
      this.$emit('campaignUpdated', this.campaign_data);
    },
    /**
     * Set the filters
     * @param {Array} filters
     */
    setFilters(filters) {
      this.campaign_data.patient_cohort = filters;
      this.onUpdateCampaign();
    },
    /**
     * Open the refine cohort modal
     */
    openCampaignRefineCohortModal() {
      this.displayRefineCohortModal = true;
    },
  }
}
</script>

<style lang="scss" scoped>
.edit-form-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
}

.p-button {
  font-size: 14px;
  border-radius: 6px;
  border: none;
  min-width: 100px;
  font-weight: 700;
}

.button-dark-grey {
  background: #7B7E7E;
  color: #FFFFFF;
  line-height: 1.5em;
}

.button-dark-grey:hover {
  background: #5b5e5e !important;
}

.p-message.p-message-warn {
  background: #FFFFFF;
  border: solid 1px #EF9600;
  border-radius: 6px;
  padding: 4px 10px 4px 10px;
  margin: 0;
  color: #EF9600;
}
.p-message :deep(.p-message-wrapper) {
  padding: 0;
}
.p-message :deep(.p-message-text) {
  font-size: 14px;
  font-weight: 700;
}
</style>