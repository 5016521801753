<template>
  <div
    class="d-flex flex-column flex-item"
    style="gap: 10px;"
  >
    <div class="font-weight-700 text-black">
      Templates
    </div>
    <p
      style="margin: 0;" 
      class="fs-16 font-weight-500"
    >
      Add templates to campaign. These will be available to you every time you send a message for this campaign.
    </p>
    <Card 
      class="card-pa-0 mb-4 overflow-hidden d-flex flex-column flex-item"
      style="min-height: 200px;"
    >
      <template #content>
        <div class="d-flex">
          <div
            class="d-flex flex-column flex-item"
            style="min-width: 300px; width: 300px;"
          >
            <div>
              <div
                class="p-input-icon-left p-input-icon-right flex-item left-margin"
                style="width: 100%;"
              >
                <i class="pi pi-search px-1"></i>
                <InputText
                  v-model="search"
                  placeholder="Templates"
                  class="grey-input border-none"
                  style="width: 100%; padding: 14px 32px;"
                  @input="searchTemplates"
                />
                <i 
                  class="pi pi-sliders-h px-1"
                  style="cursor: pointer;"
                  @click="toggleFilter"
                >
                </i>
              </div>
              <OverlayPanel
                ref="templatesOverlay"
              >
                <div class="flex-row">
                  <Checkbox
                    v-model="templateType"
                    value="sms"
                    class="mr-2"
                    @change="searchTemplates"
                  />
                  <label class="mr-2">
                    SMS
                  </label>
                </div>  
                <div class="flex-row top-margin">
                  <Checkbox
                    v-model="templateType"
                    value="email"
                    class="mr-2"
                    @change="searchTemplates"
                  />
                  <label class="mr-2">
                    Email
                  </label>
                </div>  
              </OverlayPanel>
            </div>
            <Divider class="my-0" />
            <div
              class="add-new-template"
              @click="openAddNewTemplateModal"
            >
              + Add New Template
            </div>
            <div
              style="flex: 1; overflow: auto; margin-top: 1px;"
            >
              <DataTable
                ref="templateVirtualScroller"
                v-model:selection="campaign_templates"
                :value="templates"
                :lazy="true"
                :loading="loading"
                :scrollable="true"
                scroll-height="flex"
                style="overflow: auto;"
                :virtual-scroller-options="{ lazy: true, onLazyLoad: lazyLoadTemplateList, itemSize: 50, numToleratedItems: 5, style: { flex:1 } }"
                @row-select-all="onUpdateTemplate"
                @row-select="onUpdateTemplate"
                @row-unselect-all="onUpdateTemplate"
                @row-unselect="onUpdateTemplate"
              >
                <template #loading>
                  <ProgressSpinner />
                </template>
                <template #empty>
                  {{ loading ? '' : 'No Templates Found' }}
                </template>
                <Column
                  selection-mode="multiple"
                  header-style="width: 30px; padding-right: 0;"
                />
                <Column field="name">
                  <template #header>
                    Select All
                  </template>
                </Column>
              </DataTable>
            </div>
          </div>
          <Divider
            layout="vertical"
            class="mx-0"
          />
          <div class="pa-3">
            <div class="fs-14 font-weight-500 mb-3">
              {{ campaign_templates.length }} Selected
            </div>
            <div
              class="d-flex flex-wrap ga-2"
              style="max-height: 294px; overflow: auto;"
            >
              <template
                v-for="template in campaign_templates"
                :key="template.template_id"
              >
                <Chip
                  :label="template.name"
                  removable
                  @remove="onRemoveTemplate(template.template_id)"
                />
              </template>
            </div>
          </div>
        </div>
      </template>
    </Card>
    <CreateTemplateModal
      v-if="displayAddNewTemplateModal"
      @template-created="onTemplateAdded"
      @close-modal="displayAddNewTemplateModal = false"
    />
  </div>
</template>

<script>
import axios from 'axios';
import CreateTemplateModal from '@/components/modals/create-template-modal.vue';
import OverlayPanel from 'primevue/overlaypanel';

export default {
  components: {
    OverlayPanel,
    CreateTemplateModal
  },
  props: {
    modelValue: {
      type: Array,
      default: () => []
    },
    preSelectedTemplates: {
      type: Array,
      default: () => []
    },
    modalSource: {
      type: String,
      default: 'campaign'
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      campaign_templates: [],
      templates: [],
      template_select_all: false,
      loading: false,
      current_page: 1,
      page_count: 1,
      search: '',
      templateType: ['sms', 'email'],
      displayAddNewTemplateModal: false,
    }
  },
  computed: {
    templateTypeSelection() {
      if (this.templateType.length !== 1) {
        return "both";
      }
      else {
        return this.templateType[0];
      }
    },
    payload() {
      let payload = {
        "search_term": this.search,
        "sms_template": this.templateType.includes('sms'),
        "email_template": this.templateType.includes('email'),
        "page": this.current_page
      }

      return payload;
    },
  },
  watch: {
    preSelectedTemplates: {
      handler() {
        if (this.preSelectedTemplates.length > 0 ) {
          if (this.modalSource === 'recommended') {
            this.campaign_templates = this.templates.filter(template => this.preSelectedTemplates.some(item => item.template_id === template.template_recommendation_id))
          }
          else {
            this.campaign_templates = this.templates.filter(template => this.preSelectedTemplates.some(item => item.template_id === template.template_id))
          }
        }
      },
      deep: true
    }
  },
  async mounted() {
    await this.getTemplates();
    if (this.preSelectedTemplates.length > 0 ) {
      if (this.modalSource === 'recommended') {
        this.campaign_templates = this.templates.filter(template => this.preSelectedTemplates.some(item => item.template_id === template.template_recommendation_id))
      }
      else {
        this.campaign_templates = this.templates.filter(template => this.preSelectedTemplates.some(item => item.template_id === template.template_id))
      }
    }
  },
  methods: {
    onRemoveTemplate(template_id) {
      this.campaign_templates = this.campaign_templates.filter(template => template.template_id !== template_id)
      this.onUpdateTemplate()
    },
    async lazyLoadTemplateList(event) {
      let scrollerRef = this.$refs.templateListVirtualScroller
      let lastPos = scrollerRef.lastScrollPos

      if (event.last >= this.templates.length && this.current_page < this.page_count && !this.loading) {
        this.current_page += 1
        await this.getTemplates()
        scrollerRef.scrollTo({
          left: 0,
          top: lastPos,
        })
      }
    },
    async getTemplates() {
      this.$store.dispatch('getRefreshToken');
      this.loading = true
      if (this.current_page === 1) {
        this.templates = [];
      }

      let api = this.modalSource === 'recommended' ? 'api/template-recommendation/company/list': '/api/template/list/enterprise';
      await axios.post(api, this.payload , this.$store.state.header)
      .then(res => {
        this.templates = this.templates.concat(res.data.templates)
        this.campaign_templates = this.templates.filter(template => this.modelValue.some(item => item.template_id === template.template_id))
        this.current_page = res.data.current_page
        this.page_count = res.data.page_count
        this.loading = false
      }).catch(err => {
        console.log(err)
        this.loading = false
      });
    },
    searchTemplates(event) {
      this.current_page = 1

      if (event.type === 'keydown' && event.key === 'Enter') {
        clearTimeout(this.delayTimer)
        this.$store.dispatch('getRefreshToken')
        this.getTemplates()
      }
      else {
        clearTimeout(this.delayTimer)
        this.delayTimer = setTimeout(() => {
          this.$store.dispatch('getRefreshToken')
          this.getTemplates()
        }, 400);
      }
    },
    onUpdateTemplate() {
      this.$nextTick(() => {
        const selectedTemplates = this.campaign_templates.map(item => {
          return {
            template_id: item.template_id,
            template_recommendation_id: item.template_recommendation_id
          }
        });
        this.$emit('update:modelValue', selectedTemplates)
      })
    },
    /**
     * Toggle the filter
     */
     toggleFilter(event) {
      console.log('event', event)
      this.$refs.templatesOverlay.toggle(event);
    },
    /**
     * Open the add new template modal
     */
     openAddNewTemplateModal() {
      this.displayAddNewTemplateModal = true;
    },
    /**
     * When a template is added
     */
     onTemplateAdded(template) {
      this.templates.unshift(template);
      this.campaign_templates.push(template);
      this.onUpdateTemplate();
    },
  }
}
</script>

<style lang="scss" scoped>
.p-card {
  border: solid 1px #BDC4C4;
  border-radius: 10px;
  box-shadow: none;
  color: #7B7E7E;
}

:deep(.p-card-content) {
  flex: 1;
  display: flex;
  padding: 0 !important;
}

.card-pa-0 :deep(.p-card-body) {
  padding: 0;
  display: flex;
  flex: 1;
}

:deep(.p-datatable .p-datatable-thead) {
  background-color: #FFFFFF !important;
}

:deep(.p-datatable .p-datatable-thead > tr > th) {
  font-size: 14px;
  font-weight: 500;
  padding: 5px 10px;
  border: none;
  background: #FFFFFF;
}

:deep(.p-datatable .p-column-header-content) {
  color: #7B7E7E;
}

:deep(.p-datatable .p-datatable-tbody > tr > td) {
  color: #7B7E7E;
  font-size: 14px;
  font-weight: 500;
  padding: 2px 10px;
  border: none;
}

.p-chip {
  background: $strongblue;
  color: #FFFFFF;
  border-radius: 5px;
  font-weight: 600;
  font-size: 14px;
}

.p-chip :deep(.p-chip-remove-icon) {
  margin-left: 1rem;
}

.top-margin {
  margin-top: 1rem;
}

.add-new-template {
  padding-left: 1em; 
  padding-top: 0.25em; 
  color: $strongblue;
  font-weight: 500; 
  cursor: pointer; 
  text-decoration: underline;
}

:deep(th) {
  height: 1rem !important;
}
</style>